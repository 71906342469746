#postAsAddPaper {
    display: flex;
}

.tagsAddPaper {
    // border-style: solid;
    border-color: $grey-border;
    border-width: 2px;
    // border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    // background-color: rgb(221, 26, 146);
}

.checkBoxDivAddPaper {
    width: 100%;
    margin-top: 5px;
    display: flex;
    // align-items: flex-end;
}

.labelAddPaper {
    margin-left: 4px;
}

.needFlexAddPaper {
    display: flex;
}