.myClaimsAnchorSpan {
    padding-right: 20px;
    padding-top: 10px;
    // text-align: center;
    // display: flex;
    // align-items: center;
    width: 100%;
}

.myClaimsAnchor {
    text-decoration: none;
    color: blue;
    // background-color: red;
}

.myClaimsAnchor:hover {
    text-decoration: underline;
    cursor: pointer;
}

.timeStampDivMyClaims {
    padding-top: 20px;
    font-size: 90%;
    font-style: italic;
}

.userClaimMyClaims {
    display: flex;
    justify-content: space-between;
    background-color: $background-grey;
    min-height: 75px;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    width: 100%;
}

.claimStatsWidgetMyClaims {
    min-width: 100px;
    height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $white-border;
    border-radius: 5px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
}

.userClaimWrapperMyClaims {
    padding-bottom: 20px;
    display: flex;
    width: 100%;
}

.myClaimsMajorContainer {
    margin-top: 20px;
    // background-color: blue;
    // width: ;
    max-width: 1000px;
    // min-width: 1000px;
    // min-height: 600px;
    width: 100%;
    // justify-content: end;
    display: flex;
}

.center {
    display: flex;
    justify-content: center;
    // background-color: red;
}

.myClaimsWrapperContainer {
    padding-left: 20px;
    // padding-right: 20px;
    // background-color: purple;
    width: calc(100% - 200px);
    // width: 100%;
}


.dropDownMyClaimsClaimWrapper {
    position: absolute;
}

.dropDownMyClaims {
    position: absolute;
    width: 250px;
    margin-top: -5px;
    margin-left: -200px;
    // padding-top: 10px;
    padding: 5px;
    // float: right;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    
}

.input21MyClaims {
    background-color: $button-grey;
    border-radius: 27px;
    height: 35px;
    padding: 8px;
    width: 35px;
}

.input21MyClaims:hover {
    background-color: $button-grey-hover;
    cursor: pointer;
}

.optionsAnchorDivMyClaims {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: left;
    align-items: end;
    font-size: 80%;
    // align-items: center;
  
}

.optionsAnchorDivMyClaims:hover {
    background-color: $lightened-light-green;
    transition: 0.2s;
    cursor: pointer;
}

.deleteButtonMyClaim {
    // padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $dark-green;
    height: 40px;
    width: 80px;
}

.deleteButtonMyClaimClicked {
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $lightened-light-green;
    height: 40px;
    width: 80px;
}
.deleteButtonMyClaim:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.deleteButtonMyClaimWrapper {
    height: 70px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.spinnerDeleteButton {
    height: 20px;
    width: 20px;
    background-color: aqua;
}

.addClaimButtonMyClaims {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.myHeadersMyClaims {
    text-align: center;
    margin: 0px;
    width: 100%;
}

.addDivMyClaims {
    background-color: rgb(98, 189, 184);
    color: white;
    max-height: 50px; 
    border-radius: 50%;
    width: 34px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px;
}

.addDivMyClaims:hover {
    background-color: $lightened-light-green;
}

.myClaimsTabContainer {
    border-color: rgb(121, 156, 156);
    background-color: white;
    width: 200px;
    border-radius: 5px;
    border-width: 1px;
    padding: 20px;
    padding-top: 0px;
    box-shadow: $heavy-shadow;
}


.myClaimsTab {
    margin-top: 20px;
    padding: 10px 0px;
    background-color: white;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    caret-color: transparent;
}

.myClaimsTabSelected {
    margin-top: 20px;
    padding: 10px 0px;
    color: $light-green;
    background-color: white;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    caret-color: transparent;
    font-weight: bold;
}

.myClaimsTabSelected:focus {
    outline: none;
}
.myClaimsTab:hover {
    cursor: pointer;
    background-color: $light-green;
    transition: 0.2s;
}

@media (max-width: 1000px) {

    .myClaimsWrapperContainer {
        width: calc(100% - 175px)
    }

    .myClaimsTabContainer {
        max-width: 175px;
        // background-color: red;

    }
}

@media (max-width: 700px) {

    .myClaimsWrapperContainer {
        width: 100%;
        padding-left: initial;
    }

    .myClaimsTabContainer {
        max-width: initial;
        width: 100%;
        padding-right: 150px;
        padding-left: 150px;
        padding-top: 5px;
        // background-color: red;
    }

    .myClaimsMajorContainer {
        display: inline;
    }
}