.SettingsProfileInformation {
    padding-top: 10px;
}

.SettingsResetPassword {
    display: flex;
    // background-color: red;
    // align-items: center;
}

.SettingsImgProfile {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: $light-green;
    flex-shrink: 0;
}

.SettingsSubheader {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    font-style: italic;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    // background-color: aqua;

}

.SettingsLinkSentText {
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.SettingsVerticalCenter {
    display: flex;
    align-items: center;
    // background-color: yellow;
    margin-top: 10px;
}

.SettingsSubheader2 {
    padding-top: 15px;
    padding-bottom: 10px;
    justify-content: space-between;
    // padding-left: 10px;
    // text-align: center;
    font-style: italic;
    display: flex;
    text-align: center;
    align-items: center;
}

.SettingsSubheader3 {
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: space-between;
    // padding-left: 10px;
    // text-align: center;
    font-style: italic;
    display: flex;
    text-align: center;
    align-items: center;
}

.SettingsAnon-tag {
    text-decoration: none;
    color: blue;
    // float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 2px;
    font-size: 14px;
    caret-color: transparent;
    white-space: nowrap;
    display: inline;
}

.SettingsAnon-tagDiv {
    // display: flex;
    padding-bottom: 10px;
    padding-top: 10px;
}

.SettingsAnon-tagDesc {
    padding-top: 5px;
}

.SettingsSVGEdit {
    text-decoration: none;
    font-style: normal;
    color: white;
    background-color: $light-green;
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding: 5px;
    padding-top: 3px;
    border-radius: 5px;
    border-width: 1px;
}

.SettingsEditProfButton {
    text-decoration: none;
    font-style: normal;
    color: white;
    background-color: $light-green;
    // margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px 5px 10px;
    // padding-top: 3px;
    border-radius: 5px;
    border-width: 0px;
    min-width: 160px;
    min-height: 35px;
}

.SettingsEditProfButton:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
    transition: 0.2s;
}

.SettingsSVGEditIcon {
    margin-left: 5px;
    // margin-bottom: 3px;
    // background-color: yellow;
}

.SettingsSVGSaveIcon {
    margin-left: 5px;
    margin-top: 2px;
}

.SettingsEditText {
    padding-top: 2px;
    padding-left: 2px;
}

.SettingsSVGEdit:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
    transition: 0.2s;
}

.SettingsNameEdit {
    // float: left;
    font-family: Arial, Helvetica, sans-serif;   
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: none;
    padding-left: 5px;
    padding-right: 5px;
    flex-grow: 1;
    margin-left: 5px;
}

.SettingsPasswordEnterDelete {
    // float: left;
    font-family: Arial, Helvetica, sans-serif;   
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: none;
    padding-left: 5px;
    padding-right: 5px;
    flex-grow: 1;
}

.SettingsPasswordEnterWrapper {
    padding-top: px;
    display: flex;
    align-items: center;
}

.SettingsProfileInformation2 {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.SettingsPasswordInvalid {
    color: red;
}

.SettingsDeletePadding {
    padding-top: 10px;
}

.SettingsDeleteButtonPadding {
    padding-top: 5px;
}

.SettingsProfileLine {
    margin-top: 10px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.SettingsProfileLine2 {
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.SettingsTimeZoneSelector {
    margin-left: 5px;
    // background-color: $background-grey;
    // border-radius: 4px;
    // border-color: $grey-border;
    // border-style: solid;
    // border-width: 1px;et
    height: 30px;
    display: flex;
    align-items: center;
}

.SettingsTimeZoneSelector:select {
    background-color: red;
}

.SettingsEmailUnderline {
    text-decoration: underline;
    color: blue;
}

.SettingsDeleteAccountButton {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: $delete-red;
    border-width: 0px;
    margin-top: 10px;
    min-width: 150px;
}

.SettingsResetPasswordButton {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: $light-green;
    border-width: 0px;
    margin-top: 10px;
    min-width: 150px;
}

.SettingsResetPasswordButton:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
    transition: 0.2s;
}

.SettingsDeleteAccountButton:hover {
    transition: 0.2s;
    background-color: $lightened-delete-red;
    cursor: pointer;
}

.SettingsEditSVGDiv {
    display: flex;
    // background-color: red;
}


.SettingsApplyButton {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: $light-green;
    border-width: 0px;
    min-width: 150px;
    font-style: normal;
}

.SettingsApplyButton:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}


.SettingsCancelEdits {
    text-decoration: none;
    font-style: normal;
    color: white;
    background-color: $delete-red;
    display: flex;
    // align-items: center;
    padding: 5px;
    // padding-top: 3px;
    border-radius: 5px;
    border-width: 0px;
    // border-color: $delete-red;
}

.SettingsCancelEdits:hover {
    cursor: pointer;
}

.fileUploadMySettings {
    display: flex;
    padding-top: 5px;
    // background-color: red;
    // padding-bottom: 25px;
}