$dark-green: #002c2c;
$light-green: rgb(98, 189, 184);
$lightened-light-green: rgb(136, 207, 204);
$light-shadow: rgba(15, 15, 15, 0.2) 0px 0px 1.5px 0px;
$heavy-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
$white-border: rgb(171, 183, 183);
$background-grey: #F5F7FA;
$button-grey: rgb(231, 223, 223);
$button-grey-hover: rgb(197, 191, 191);
$delete-red: rgb(228, 92, 92);
$lightened-delete-red: rgb(233, 152, 152);
$grey-border: rgb(208, 208, 218);
$colorsyeah: rgb(189, 112, 98);