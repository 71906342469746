

.doi {
    width: 100%;
    height: 40px;
}


.AddFigureForm {
    // background-color: brown;
    padding-bottom: 30px;
}

#FigureImg {
    max-width:100%;
    max-height:750px;
    width:auto;
    height:auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.AddFigureSelectImage {
    display: flex;
    justify-content: left;
    padding-bottom: 5px;
}

.fullTextArea {
    width: 100%; 
    height:200px;
    border-radius: 10px;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 20px;
    background-color: $background-grey;
}

.halfTextArea {
    width: 100%; 
    height:100px;
    border-radius: 10px;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 20px;
    background-color: $background-grey;
}

#SubmitNewFigureButton {
    width: 300px;
    // margin-top: 100px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    // box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.SubmitNewFigureButton {
    width: 300px;
    // margin-top: 100px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    // box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.SubmitNewFigureButtonClicked {
    width: 300px;
    // margin-top: 100px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    
    border-radius: 5px;
    color: white;
    background-color: $light-green;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    // box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.centerButtonDiv {
    display: flex;
    justify-content: center;
    // align-items: center;
    // background-color: burlywood;
}

input[type=file]::file-selector-button {
    background-color: #002c2c;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}

input[type=file]::file-selector-button:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.centered {
    display: flex;
  justify-content: center;
  align-items: center;
//   padding-bottom: 20px;
}

.centerHeaderAddFigure {
    // background-color: aqua;
    font-size: 180%;
    text-align: center;
}

#SubmitNewFigureButton:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.fileUploadAddFigure {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
}

.container {
    padding-top: 20px;
}

.loadingBarParent2 {
    // padding-top: 15px;
    display: flex;
    // background-color: cadetblue;
    justify-content: center;
    padding-bottom: 20px;
}

.AddFigureMajorContainer2 {
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    margin: auto;
    margin-top: 20px;
    max-width: 1000px;
    padding: 20px;
    // padding-top: 0px;
    text-align: center;
}

.extractedDivAddFigure {
    // background-color: red;
    display: flex;
    // width: 80%;
    // margin: auto;
    align-items: center;
    padding-top: 10px;
    padding-left: 15px;
}

.postAsAddFigure {
    // background-color: green;
    // width: calc(100% - 39px); 
    // float: left;
    display: flex;
    padding-bottom: 10px;
}

.postAsSpanAddFigure {
    display: flex;
    align-items: center;
}