.theoryMajorContainer {
    max-width: 1500px;
    margin: auto;
    // background-color: red;
}

.theoryText {
    padding: 0 1.3rem;
    padding-top: 40px;
    padding-bottom: 60px;
}

.comprehensivenessDiv {
    padding: 0 1.3rem;
    padding-top: 20px;
    // background-color: aqua;
    display: flex;
    
    justify-content: center;
}

.comprehensivenessVote {
    display: flex;
    background-color: $background-grey;
    border-radius: 10px;
    box-shadow: $heavy-shadow;
    justify-content: space-between;
    height: 60px;
    width: 300px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
}

.comprehensivenessQuestion {
    text-align: center;
}

.comprehensiveness {
    padding-top: 40px;
}

.comprehensivenessCheckbox {
    // border-radius: 20px;
    width: 16px;
    height: 16px;
}

.comprehensivenessVoteWrapper {
    display: flex;
    align-items: center;
}

.comprehensivenessSubmit {
    margin-left: 10px;
    padding: 5px;
    height: 40px;
    width: 80px;
    color: white;
    background-color: $dark-green;
    cursor: pointer;
    border: none;
    font-size: 18px;
    border-radius: 5px;
    font-family: Arial;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    // display: flex;
    text-align: center;
    // align-content: center;
    // justify-content: center;
}

.comprehensivenessSubmit:hover {
    background-color: $light-green;
    transition: 0.2s;
}

.comprehensivenessSubmitClicked {
    margin-left: 10px;
    padding: 5px;
    height: 40px;
    width: 80px;
    color: white;
    background-color: $light-green;
    cursor: pointer;
    border: none;
    font-size: 18px;
    border-radius: 5px;
    font-family: Arial;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    // display: flex;
    text-align: center;
    // align-content: center;
    // justify-content: center;
}

.TheoryPlaceCursor {
    // background-color: aqua;
    display: flex;
    justify-content: center;
}

.TheorySpinnerShift {
    padding-top: 5px;
}

.checkBoxAndNumberDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
}

.checkBoxAndNumber {
    // background-color: purple;
    // width: 30px;

    // display: flex;
}

.comprehensivenessNumberDiv {
    display: flex;
    justify-content: center;
}

.containerTheory {
    max-width: 100rem;
    margin: 30px auto;
    padding: 1.3rem;
    padding-top: 1rem;
    // padding-bottom: 1.6rem;
    
    background-color: white;
    // padding-bottom: 20px;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
}

.loginTheory {
    text-align: center;
    font-weight: bold;
    height: 80px;
    
    font-size: 20px;
    padding-top: 200px;
}

.loginTheory2 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: bold;
    // height: 80px;
    // background-color: red;
    font-size: 20px;
    padding-top: 30px;
}



.newTheoryHere {
    color: blue;
    padding-left: 5px;
}

.newTheoryHere:hover {
    text-decoration: underline;
    cursor: pointer;
}

.newTheoryHereWrapper {
    padding-top: 20px;
    display: flex;
    font-size: 90%;
}

.theoryTitleandStats {
    // background-color: blueviolet;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.confidenceAndStatsTheory {
    background-color: $background-grey;
    min-width: 180px;
    border-radius: 10px;
    box-shadow: $heavy-shadow;
}

.smallConfidenceAndFollowTheory {
    // padding-top: 20px;
    // display: flex;
    // justify-content: space-evenly;
    // align-items: center;
    display: none;
}

.confidenceAndStatsTheory2 {
    display: none;
}

.confidenceAndStatsTheory h1 {
    font-size: 100%;
}

.confidenceAndStatsTheory2 h1 {
    font-size: 100%;
}

.followSVGTheory {
    // background-color: blueviolet;
    min-width: 150px;
    height: 100px;
    justify-content: center;
    padding: 10px;
    display: flex;
    align-items: center;
    right: 0px;
    position: relative;
}



.followSVGSVGTheory {
    // background-color: brown;
    border-style: solid;
    border-width: 5px;
    border-color: $dark-green;
    width: 50px;
    padding: 5px;
}

.followSVGSVGTheory {
    cursor: pointer;
}

.followSVGSVGTheoryClicked {
    background-color: $dark-green;
    border-style: solid;
    border-width: 5px;
    border-color: $dark-green;
    width: 50px;
    padding: 5px;
}

.followSVGSVGTheoryClicked:hover {
    cursor: pointer;
}

.confidenceLoader {
    // background-color: aqua;
    display: flex;
    align-items: center;
    // height: 300px;
}

.followSVGTheorySmall {
    display: none;
}

.h1TheoryTitle {
    padding-left: 20px;
    padding-right: 20px;
    // background-color: brown;
}


.noShowFollow {
    min-width: 150px;
    height: 100px;
    justify-content: center;
    padding: 10px;
    display: flex;
    align-items: center;
    visibility: hidden;
}

.followSVGTheory {
    // background-color: blueviolet;
    min-width: 150px;
    height: 100px;
    justify-content: center;
    padding: 10px;
    display: flex;
    align-items: center;
    
}


.dropDownTheory {
    position: absolute;
    width: 250px;
    margin-top: 50px;
    margin-left: -250px;
    // top: 0px;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.dropDownTheoryLarge {
    position: absolute;
    width: 250px;
    margin-top: 20px;
    margin-left: -200px;
    // top: 0px;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.ConfidencePercentageDivTheory {
    display: flex;
    justify-content: space-evenly;
    // background-color: chartreuse;
}

@media (max-width: 1000px) {
    .theoryTitleandStats {
        display: block;
    }

    .smallConfidenceAndFollowTheory {
        padding-top: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .confidenceAndStatsClaim2 {
        display: block;
        background-color: $background-grey;
        min-width: 180px;
        border-radius: 10px;
        box-shadow: $heavy-shadow;
    }
    .followSVGTheory {
        display: none;
    }

    .confidenceAndStatsTheory {
        display: none;
    }

    .confidenceAndStatsTheory2 {
        display: initial;
        background-color: $background-grey;
        min-width: 180px;
        border-radius: 10px;
        box-shadow: $heavy-shadow;
    }

    .followSVGTheorySmall {
        display: initial;
    }

  
}