* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  /* color: #333333; */
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  background-color: #F5F7FA;
}

h1 {
    font-size: 150%;
}

h2 {
    font-size: medium;
}

h3 {
    font-size: medium;
}

p {
    font-size: 90%;
}
.criticiser {
    font-size: 90%;
    text-decoration: none;
    float: left;
}

.criticiser:hover {
    font-size: 90%;
    text-decoration: underline;
    color: blue;
}

.figureCritRightSmall {
    display:block;
    background-color: white;
    margin-left: 0%;
    width: 100%;
    max-height: 600px;
    margin-top: 1%;
    float: left;
    border-radius: 4px;
    /* border-style: solid; */
    border-width: 1px;
    border-color: rgb(171, 183, 183);
    padding: 20px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
}
.majorContainer {

    max-width: 1500px;
    margin: auto;
}

#description {
    padding: 5px;
    background-color: white;
    border-radius: 5px;
}

#methods {
    padding: 5px;
    background-color: white;
    border-radius: 5px;
}



textarea {
    padding: 3%;
}

// .figureShowImage {
//     cursor: pointer;
//     border-style: solid;
//     border-width: 1px;
//     border-color: black;
//     border-radius: 5px;
//     background-color: white;
//     width: 100%;
// }

#lightbox {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: none;
  }
  
  #lightbox.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #lightbox img {
    width: 90%;
    padding: 4px;
    background-color: black;
    border: 1px solid white;
  }




.center {
    // justify-content: center;
    // align-items: center;
    // background-color: blue;
    // height: 300px;
}

.whiteBackground {
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    max-height: 100vh;
}

.loginNoticeMyPage {
    text-align: center;
    font-weight: bold;
    height: 80px;
    font-size: 20px;
    padding-top: 100px;
}

.myHeaders {
    text-align: center;
    padding-bottom: 20px;
    margin: 0px;
}





.optionsClaimWidget {
    display: flex;
    align-items: center;
    // background-color: blue;
    padding-left: 20px;
}





.myClaimsClaimContainer {
    background-color: white;
    min-height: 600px;
    border-color: rgb(121, 156, 156);
    box-shadow: $heavy-shadow;
    border-radius: 5px;
    padding: 20px;
    // max-width: 800px;
}





.noClaimsToShow {
    padding: 20px;
    padding-top: 50px;
    // background-color: yellow;
    display: flex;
    justify-content: center;
    // width: 100%;
    min-height: 50px;
}

.noClaimSpacer {
    width: 20px;
    // background-color: black;
    height: 10px;
}

.fullWidthMyClaims {
    width: calc(100% - 20px);
    background-color: blue;
}

.userClaimWrapper {
    padding-bottom: 20px;
    display: flex;
    width: 100%;
}

.userClaim {
    display: flex;
    justify-content: space-between;
    background-color: $background-grey;
    min-height: 75px;
    padding: 15px;
    border-radius: 5px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    width: 100%;
}

.sortByDivMyClaimWrapper {
    padding-bottom: 20px;
    display: flex;
    // position: relative;
    // width: 200px;
    // background-color: red;
}

.sortByDivMyClaim {
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    display: flex;
    min-width: 120px;
    justify-content: space-between;
    // height: 50px;
    align-items: center;
}

.slightSpacerMyClaim {
    padding-right: 10px;
    // background-color: red;
    display: flex;
    align-items: center;
}

.tagsSpanMyClaims {
    // margin-left: 10px;
    padding-left: 8px;
    // background-color: blue;
    display: flex;
    align-items: center;
    // float: right;
}

.seletorsDivMyClaims {
    position: absolute;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 3px;
    width: 150px;
    top: 25px;
    left: -1px;
    background-color: white;
    width: 100%;
    z-index: 5000;
    // left: 60px;
}

.selectorOptionsMyClaims {
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 2px;
    padding-left: 5px;
}

.selectorOptionsMyClaims:hover {
    background-color: $lightened-light-green;
    cursor: pointer;
    transition: 0.2s;
    // color: white;
}



.RestCursorPointer:hover {
    cursor: pointer;
}












