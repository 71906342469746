.majorContainerPaper {
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    margin: auto;
    margin-top: 10px;
    max-width: 1000px;
    padding: 20px;
    text-align: center;
}


.followSVGPaper {
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
}


.optionsPaper {
    display: flex;
    align-items: center;
}

.myHeadersPapers {
    margin: 0px;
    width: 100%;
    text-align: left;
    // padding-left: 10px;
}

.addedByPaper {
    font-size: 14px;
}

.doiPaper {
    color: rgb(43, 81, 185);
    text-align: left;
    padding-top: 30px;
}

.journalPaper {
    text-align: left;
    padding-top: 10px;
}

.divAbovePaper {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 10px;
    // padding-bottom: 20px;
}

.dropDownPaper {
    position: absolute;
    width: 200px;
    margin-top: 15px;
    margin-left: -150px;
    z-index: 1000;
    // padding-top: 10px;
    padding: 5px;
    // float: right;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    
}

.titleHeaderPaper {
    // background-color: aquamarine;
    display: flex;
    align-items: center;
    font-size: 17px;
}

.titleDivPaper {
    // display: flex;
    // justify-content: center;
}

.noClaimsToShowPaper {
    padding-top: 20px;
    padding-bottom: 40px;
    // background-color: yellow;
    display: flex;
    justify-content: center;
    // width: 100%;
    min-height: 50px;
}

.anchorSpanPaper {
    padding-right: 20px;
    justify-content: start;
    padding-top: 25px;
    text-align: left;
    // display: flex;
    // align-items: center;
    width: 100%;
    // background-color: red;
}

.timeStampDivPaper {
    padding-top: 20px;
    font-size: 90%;
    font-style: italic;
    // background-color: aqua;
}

.flexPaper {
    display: flex;
    justify-content: space-between;
    // background-color: yellow;
}

.sortByDivPaperWrapper {
    // padding-bottom: 20px;
    display: flex;
    // position: relative;
    // width: 200px;
    // background-color: red;
}

.sortByDivPaper {
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    display: flex;
    min-width: 120px;
    justify-content: space-between;
    // height: 50px;
    align-items: center;
}

.selectorOptionsPaper {
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 2px;
    text-align: left;
}

.selectorOptionsPaper:hover {
    background-color: $lightened-light-green;
    cursor: pointer;
    transition: 0.2s;
    // color: white;
}

.smallTextAreaPaper {
    width: 100%; 
    height: 60px;
    border-radius: 10px;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 20px;
    background-color: $background-grey;
}

.deleteButtonPaper {
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $dark-green;
    height: 40px;
    // width: 80px;
}

.deleteButtonPaperClicked {
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $lightened-light-green;
    height: 40px;
    width: 80px;
}
.deleteButtonPaper:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}