




.theoryPage {
    border-color: rgb(121, 156, 156);
    background-color: white;
    margin: auto;
    width: 800px;
    border-radius: 4px;
    
    
    /* border-style: solid; */
    border-width: 1px;
    padding: 20px;
    padding-top: 1px;
    box-shadow: $heavy-shadow;
    min-height: 800px;
}

.theoryTextDiv:focus {
    outline: none;
    white-space: pre-wrap;
    
}

.theoryTextDiv {
    min-width: 30px;
    font-family: Arial, Helvetica, sans-serif;
    display: inline;
    // background-color: red;
}

.claimReference {
    // width: 200px;
    // height: 200px;
    margin-right: 5px;
    margin-left: 5px;
    color: rgb(5, 128, 210);
    // background-color: aqua;
    // display: inline-block;
    text-align: center;
    // tabindex="-1"
    position: relative;
    // background-color: aqua;
}

.claimReferenceHover {
    width: 400px;
    // height: 100px;
    padding: 10px;
    background-color: $background-grey;
    border-radius: 10px;
    border-style: solid;
    border-color: $grey-border;
    border-width: 1px;
    display: none;
    position: absolute;
    left: -140px;
    top: 13px;
    z-index: 5000;

    // bottom: 0px;
    // margin-bottom: 15px;
}

.claimReferenceHover:hover {
    display: block;
}

.claimReferenceAnchor {
    color: blue;
}

.claimReferenceFootnote:hover + .claimReferenceHover {
    display: block;
    position: absolute;
    // margin-right: 10px;
    // right: 50px;
}

.howImportantTheoryDiv {
    color: black;
    font-size: 80%;
}

.claimRefInfo {
    display: none;
}

.halfTextAreaTheory {
    width: 100%; 
    min-height:100px;
    // overflow-y: visible;
    // overflow: auto;
    border-radius: 10px;
    background-color: white;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 20px;
}

#mytextarea {
    width: 500px;
    // height: 200px;
    resize: vertical;
}



#theTheoryDiv {
    background-color: white;
    // display: flex;
    word-break: break-all;
    // overflow-wrap: break-word;
}


.saveTheoryButtonDiv {
    display: flex;
    padding-top: 20px;
    justify-content: end;
}

.saveTheoryButton {
    width: 75px;
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    font-family: Arial;
    font-size: 20px;
    height: 40px;
    cursor: pointer;
    border: none;
    box-shadow: $light-shadow;
}

.saveTheoryButton:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.saveTheoryButtonClicked {
    width: 75px;
    border-radius: 5px;
    color: white;
    background-color: $light-green;
    font-family: Arial;
    font-size: 20px;
    height: 40px;
    cursor: pointer;
    border: none;
    box-shadow: $light-shadow
}

.centerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: aqua;

}

.AddTheoryClickDiv {
    font-size: 15px;
    // background-color: aqua;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    display: flex;
    
}

.AddTheoryClaimAnchor {
    font-weight: bold;
    text-decoration: none;
    color: blue;
}

.AddTheoryClaimAnchor:hover {
    text-decoration: underline;
}

.AddTheoryBeingSearched {
    // position: absolute;
    width: 100%;
    // left: 0;
    font-size: 120%;
    // margin-top: 50px;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
    background-color: white;
}

.AddTheoryFlex {
    display: flex;
    justify-content: space-between;
}

.containerAddTheory {
    max-width: 100rem;
    margin: 50px auto;
    padding: 0 1.3rem;
    position: relative;
    // background-color: aqua;
    // padding-bottom: 20px;
}

.containerAddTheory2 {
    max-width: 100rem;
    margin: auto;
    padding: 0 1.3rem;
    position: relative;
    // background-color: aqua;
    // padding-bottom: 20px;
}

.theFlexTheory {
    // background-color: green;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px;
    // border-style: solid;
    border-width: 2px;
    border-color: $dark-green;
    // margin-bottom: 5px;
    min-height: 45px;
    min-width: 300px;
}

.theFlexTheoryHidden {
    display: hidden;
}

.flexBelowAddTheory {
    position: relative;
    // background-color: green;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    // margin-left: 5px;
    

}

.insertReferenceButtonAddTheory {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 20px;
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    text-decoration: none;
    font-family: Arial;
    font-size: 17px;
    height: 30px;
    // position: absolute;
    cursor: pointer;
    border: none;
    // text-align: center;
    // box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.insertReferenceButtonAddTheory:hover {
    background-color: $light-green;
    transition: 0.2s;
}

.searchBarDivs {
    display: flex;
    // background-color: yellow;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
}

.red2AddTheory {
    // background-color: red;
    display: flex;
    width: 180px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.flexAddTheory {
    display: flex;
    width: 80%;
    justify-content: flex-end;
}

.searchWidthTheory {
    max-width: calc(100% - 80px);
    // background-color: red;
    text-align: left;
}

.removeReferenceButtonAddTheory {
    width: 100px;
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    font-family: Arial;
    font-size: 20px;
    height: 40px;
    cursor: pointer;
    border: none;
    box-shadow: $light-shadow
}

.flexTitleAndOptionsAddTheory {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.dropDownTheoryWrapper {
    position: absolute;
}

.dropDownAddTheory {
    // position: absolute;
    width: 250px;
    // margin-top: 15px;
    margin-left: -195px;
    // margin-left: -160px;
    margin-top: 90px;
    padding: 5px;
    // float: right;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.optionsWrapperAddTheory {
    position: absolute;
}

.centerOptionsAddTheory {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AddTheorySearchText {
    /* position: absolute; */
    // margin-left: 3%;
    // margin-top: 25px;
    width: 100%;
    height: 40px;
    font-size: 100%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
}

.HeaderSearchBarText:focus {
    outline: none;
    
    border-color: $light-green;
    border-width: 2px;
}

@media (max-width: 840px) {
    .theoryPage {
        width: 100%;
    }
}