.spacerProfile {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.headerProfile {
    display: flex;
    border-color: rgb(121, 156, 156);
    background-color: white;
    box-shadow: $heavy-shadow;
    border-radius: 5px;
    width: 100%;
    max-width: 1000px;
    padding: 10px;
    padding-left: 25px;
    position: relative;
}

.headerProfile2 {
    display: flex;
    border-color: rgb(121, 156, 156);
    background-color: white;
    box-shadow: $heavy-shadow;
    border-radius: 5px;
    width: 100%;
    max-width: 1000px;
    padding: 10px;
    padding-left: 25px;
    position: relative;
    align-items: center;
} 

.imgProfile {
 min-width: 110px;
 height: 110px;
 border-radius: 50%;
 border-style: solid;
 border-width: 2px;
 border-color: $light-green;

}

.ProfileFontBold {
    font-weight: bold;
    color: blue;
    text-decoration: none;
}

.ProfileFontBold:hover {
    text-decoration: underline;
}

.spacerHeaderProfile {
    // background-color: red;
    padding-top: 10px;
    padding-left: 20px;
    // width: 100%;
}

.spacerHeaderProfile2 {
    // background-color: red;
    padding-left: 20px;
    // width: 100%;
}

.paddingLeftHeaderProfile {
    color: $dark-green;
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 6px;
    // background-color: aqua;
}

.followersDivProfile {
    padding-top: 4px;
    font-size: 15px;
    flex-grow: 0;
    flex-basis: auto;
}

.loginNoticeProfile {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding-top: 200px;
    padding-bottom: 10px;
}

.dropDownProfile {
    position: absolute;
    width: 250px;
    // margin-top: 15px;
    // margin-left: -150px;
    left: -215px;
    top: 15px;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    
}

.optionsWrapperProfile {
    display: flex;
    // background-color: aqua;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.paddingTopAnchorProfile {
    line-height: 1.5;
    // background-color: red;
}

.anchorNameProfile {
    text-decoration: none;
    color: blue;
    // float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 2px;
    font-size: 14px;
    caret-color: transparent;
}
.anchorNameProfile0 {
    text-decoration: none;
    color: blue;
    // float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 2px;
    font-size: 14px;
    caret-color: transparent;
}

.sortByDivProfile {
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    display: flex;
    min-width: 200px;
    justify-content: space-between;
    // height: 50px;
    align-items: center;
}

.ProfileOptionsIcon {
    // background-color: blueviolet;
    min-width: 150px;
    height: 100px;
    justify-content: center;
    padding: 10px;
    display: flex;
    align-items: center;
    right: 0px;
    position: absolute;
}

.ProfileComponentsSpinnerDiv {
    width: 100%;
    height: 200px;
    // background-color: aqua;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileListContainer {
    background-color: white;
    min-height: 600px;
    border-color: rgb(121, 156, 156);
    box-shadow: $heavy-shadow;
    border-radius: 5px;
    padding: 20px;
    // max-width: 800px;
}