.majorContainer2 {
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    margin: auto;
    margin-top: 10px;
    max-width: 1000px;
    padding: 20px;
    // padding-top: 0px;
    text-align: center;
}

.claimTitle {
    text-decoration: none;
    color: blue;
}

.claimTitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

.theSpan2 {
    // position: relative;
    // background-color: green;
    // display: flex;
    // width: 100%;
    // justify-content: center;
    // margin-left: 5px;
    // margin-top: 30px;
    min-height: 500px;
}

.flexBelow{
    position: relative;
    // background-color: green;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    // margin-left: 5px;
    

}

.redTextConnectClaim {
    color: red;
}

.connectFiguresConnectClaimH2 {
    padding-bottom: 20px;
}

#search-text3 {
    /* position: absolute; */
    // margin-left: 3%;
    // margin-top: 25px;
    width: 100%;
    height: 40px;
    font-size: 100%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
}

.beingSearched2 {
    // position: absolute;
    width: 100%;
    // left: 0;
    font-size: 120%;
    // margin-top: 50px;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
    background-color: $background-grey;
}

.inputAndSearch {
    width: 100%;
}

.red2 {
    // background-color: red;
    display: flex;
    width: 120px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.searchBarDivs {
    display: flex;
    
    // background-color: yellow;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
}

.searchWidth {
    max-width: calc(100% - 80px);
    // background-color: red;
    text-align: left;
}

#inputConnectSup {
    background-color: $light-green;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    // margin-left: 
    padding: 6px;
    
}

#inputConnectCon {
    background-color: $delete-red;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    // margin-left: 
    padding: 6px;
}

#inputConnectSup:hover {
    background-color: $lightened-light-green;
    cursor: pointer;
}

#inputConnectCon:hover {
    background-color: $lightened-delete-red;
    cursor: pointer;
}

#inputConnectSupSelected {
    background-color: white;
    border-radius: 50%;
    border-color: $light-green;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    width: 30px;
    // margin-left: 
    padding: 6px;
}

#inputConnectConSelected {
    background-color: white;
    border-radius: 50%;
    border-color: $delete-red;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    width: 30px;
    // margin-left: 
    padding: 6px;
}

#inputConnectSupSelected:hover {
    background-color: $lightened-light-green;
    cursor: pointer;
}


#inputConnectConSelected:hover {
    background-color: $lightened-delete-red;
    cursor: pointer;
}




.pickFigures {
    display: flex;
    position: relative;
    justify-content: space-around;
    width: 80px;
}

.ConnectClaimFigure2 {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    min-width: 80px;
}

.ConnectClaimSmallDesc {
    // font-weight: bold;
    // background-color: yellow;

    color: rgb(96, 94, 94);

    display: flex;
    justify-content: left;
}

.ConnectClaimLargeDesc {
    font-weight: bold;
    color: blue;
    display: block;
    text-align: left;
    text-decoration: none;
    // word-wrap: break-word;

    
}

.ConnectClaimLargeDesc:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ConnectClaimDescDiv {
    // flex-grow: 0; /* Prevents the item from growing */
    // flex-shrink: 0; /* Prevents the item from shrinking */
    // flex-basis: auto; 
}

.alreadyConnected {
    position: absolute;
    left: 100px;
    top: 5px;
    width: 350px;
    background-color: blue;
    text-align: left;
}

.modalButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // background-color: yellowgreen;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    // height: 100%;
}

.modalButton button:hover {
    transition: 0.2s;
    background-color: $light-green;
}

.modalButton button {
    height: 35px;
    width: 100px;
    padding: 3px;
    color: white;
    background-color: $dark-green;
    border-style: solid;
    border-width: 3px;
    border-radius: 5px;
    border-color: $dark-green;
}

.modalButton button:hover {
    cursor: pointer;
}

.figuresToConnect {
    display: flex;
    // justify-content: flex-start;/
    flex-wrap: wrap;
    // margin-left: 20px;
    background-color: $background-grey;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: $white-border;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.contradictingFigures {
    text-align: left;
    color: $delete-red;
}

.contradictingFigures2 {
    padding-top: 20px;
    text-align: left;
    color: $delete-red;
}

.supportingFigures {
    text-align: left;
    color: $light-green;
}

.centerItConnect {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
}

.contraFigConnect {
    margin-right: 20px;
    width: calc(33.33% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    // background-color: white;
}

.contraFigConnect2 {
    // margin-right: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    // background-color: white;
}



.borderConnect {
    border-style: solid;
    width: calc(50% - 20px);
    border-width: 1px;
    border-color: $white-border;
    border-radius: 5px;
    padding-top: 5px;
    background-color: white;
}

.explanationDivConnectClaim {
    width: calc(50% - 20px);
    margin-left: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textAreaExplanationConnectClaim {
    width: 100%;
    height: 120px;
    font-family: Arial, Helvetica, sans-serif;   
    background-color: white;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: none;
    margin-bottom: 10px;
}

.figureTitleConnectClaim {
    color: blue;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
}

.figureTitleConnectClaim:hover {
    text-decoration: underline;
}

.borderConnect img {
    border-style: solid;
    border-width: 1px;
    border-color: $grey-border;
    border-left: 0;
    border-right: 0;
}

.flexConnectClaim {
    display: flex;
    width: 80%;
    justify-content: flex-end;
}

.horizontalCenterConnectClaim {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.verticalCenterConnectClaim {
    width: 100%
}

.lineConnectClaim {
    margin-top: 4px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.line2ConnectClaim {
    margin-bottom: 2px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.figureConnectClaim {
    max-width: 100%;
    max-height: 300px;
}

.figureConnectClaim:hover {
    cursor: pointer;
}

.tagsConnect {
    // border-style: solid;
    border-color: $grey-border;
    border-width: 2px;
    // border-radius: 4px;
    // padding-left: 5px;
    // padding-right: 5px;
    margin-left: 5px;
    // background-color: rgb(221, 26, 146);
    display: flex;
}

.checkBoxDivConnect {
    // width: 100%;
    // background-color: blue;
    margin-bottom: 5px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    // vertical-align: middle;
}

.labelConnect {
    margin-left: 4px;
    // margin-bottom: 20px;
    // width: 200px;
}

.checkBox1Claim {
    // padding-top: 2px;
    height: 20px;
    width: 18px;
    margin-right: 4px;
    vertical-align: bottom;
    // margin-bottom: 5px;
    // background-color: chartreuse;
}



.firstCheckConnect {
    // background-color: yellow;
    // width: 200px;
    text-align: left;
    // justify-content: left;
    // display: flex;
}

.postAsSpanConnect {
    // background-color: cyan;
    // padding-top: 2px;
    
}

.checking {
    background-color: chartreuse;
    // display: block;
    // width: fit-content;
    // width: -moz-fit-content;
    // overflow: auto;
    // width: 300px;
    // white-space: nowrap;
}

#postAsConnect {
    // background-color: green;
    // width: calc(100% - 39px); 
    // float: left;
    display: flex;
    
    // margin-bottom: 100px;
}

.connectFiguresButton {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    text-decoration: none;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    // text-align: center;
    box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}



.connectFiguresButtonClicked {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    background-color: $lightened-light-green;
    text-decoration: none;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    // text-align: center;
    box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.connectFiguresButtonLabel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.connectFiguresButton:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}