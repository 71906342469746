.theHeader {
    background: #002c2c;
    color: white;
      /* position: absolute; */
    /* padding: 1.6rem 0; */
    border-bottom: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color:rgb(121, 120, 119);
    /* border-style: solid; */
    // box-shadow: rgb(100, 100, 111) 0px 7px 29px 0px;
    // height: 175px;
    padding-top: 20px;
    padding-bottom: 20px;
  }





#div3 {
    margin-top: 1.5%;
    font-size: 190%;
}

#div4 {
    font-size: 140%;
}

#imgWrap {
    text-align: center;
    display: flex;
    align-items: center;
}

.head {
    
    // width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#LogoImg {
    width: 100px;
    // height: 50px;
    border-radius: 15px;
}

.websiteTitleLogin {
    display: flex;
    align-items: center;
    padding-left: 20px;
    // background-color: yellow;

}

.loggedInAsLogin {
    // background-color: red;
    padding-top: 10px;
    // padding-bottom: 10px;
    text-align: center;
}

.loginFormLogin {
    // background-color: blue;
}

.spacerLogin {
    height: 100px;
}

.login {
    width: 400px;
    background: #002c2c;
    margin: auto;
    // margin-top: 150px;
    color: white;
    border-radius: 10px;
    display: block;
    // margin-top: 100px;
    /* border-color: rgb(121, 156, 156); */
    // box-shadow: 3px 3px 2px red;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    padding-bottom: 20px;
}

.login h3 {
    width: 400px;
    background: #002c2c;
    margin: auto;
    color: white;
    border-radius: 20px;
    display: block;
    text-align: center;
}

.Login {
    text-align: center;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.navigateToLogin {
    text-decoration: underline;
    margin-left: 4px;
}

.navigateToLogin:hover {
    cursor: pointer;
}



.password {
    width: 300px;
    font-size: 125%;
    color: black;
}

.terms {
    font-size: smaller;
    // background-color: red;
    // width: 350px;
    // height: 35px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.already {
    text-align: center;
    // margin-top: 5%;
    padding-top: 20px;
}

.already a {
    
    color: white;
}

.fullName {
    color: black;
    width: 300px;
    height: 35px;
    font-size: 125%;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 18px;
}

.email {
    color: black;
    width: 300px;
    height: 35px;
    font-size: 125%;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 18px;
}

.password {
    color: black;
    width: 300px;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 18px;
}

.div1 {
    // background-color: aqua;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoginButtonDiv {
    // background-color: red;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}

.invalidLogin {
    text-align: center;
    padding-bottom: 10px;
    color: red;
}

.SignUp1 {
    width: 40%;
    border-radius: 5px;
    color: black;
    // margin-top: 7%;
    border: none;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    height: 30px;
    cursor: pointer;
}

.SignUp {
    border-radius: 5px;
    color: black;
    border: none;
    width: 200px;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    height: 40px;
    cursor: pointer;
}

.SignUp:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.SignUp1:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.signUpButtonDivRegister {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.loginButtonsWrapperDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    // background-color: blue;
}

.ButtonLogin {
    border-radius: 5px;
    color: black;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    border: none;
    padding: 5px;
    cursor: pointer;
}

.ButtonLogin:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.ButtonSignUp {
    border-radius: 5px;
    color: black;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    border: none;
    padding: 5px;
    cursor: pointer;
}

.ButtonSignUp:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.passwordIssues {
    text-align: center;
    // margin-top: 5%;
    color: red;
}

.LoginSpacerHeader {
    padding-top: 80px;
}

.RegisterSignUpDiv {



}

.login2 {
    width: 450px;
    background: #002c2c;
    margin: auto;
    // margin-top: 150px;
    color: white;
    border-radius: 10px;
    display: block;
    // margin-top: 100px;
    /* border-color: rgb(121, 156, 156); */
    // box-shadow: 3px 3px 2px red;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    padding-bottom: 20px;
}

.RegisterSubtitle {
    padding-bottom: 5px;
    font-size: 15px;
}

.RegisterResetSubtitle {
    padding-bottom: 10px;
    font-size: 15px;
}

.RegisterSubtitleDiv {
    // background-color: aqua;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RegisterSubtitleDiv2 {
    // background-color: aqua;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.RegisterResetSubtitle3 {
    // background-color: aqua;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullName2 {
    color: black;
    width: 400px;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 15px;
}

.email2 {
    color: black;
    width: 400px;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 15px;
}

.email3 {
    color: black;
    width: 450px;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 15px;
}

.RegisterSignUp2 {
    border-radius: 5px;
    color: black;
    border: none;
    width: 400px;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    height: 40px;
    cursor: pointer;
}

.RegisterSignUp2:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.RegisterSignUpButtonDivRegister {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.RegisterLinkSent {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    // color: yellow;
}

.Login {
    text-align: center;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.RegisterButtonLogin {
    border-radius: 5px;
    color: black;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 400px;
}

.RegisterButtonLoginLoading {
    border-radius: 5px;
    color: black;
    background-color: $lightened-light-green;
    text-decoration: none;
    font-family: Arial;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 400px;
}


.RegisterButtonLogin:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.RegisterButtonSignUp {
    border-radius: 5px;
    color: black;
    background-color: white;
    text-decoration: none;
    font-family: Arial;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 200px;
}

.RegisterButtonSignUp:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.RegisterCenterButton {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.RegisterLine {
    font-size: 1.6rem;
    color: white;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    margin-bottom: 10px;
    height: 1px;
    width: 100%;
    border-color: #e0dede;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.RegisterPadding {
    padding-right: 25px;
    padding-left: 25px;
}

.RegisterForgotPassword {
    text-align: center;
    padding-top: 20px;
}

.RegisterCenterButton2 {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.RegisterForgotPasswordSpan:hover {
    text-decoration: underline;
    cursor: pointer;
}


.login3 {
    width: 500px;
    background: #002c2c;
    margin: auto;
    // margin-top: 150px;
    color: white;
    border-radius: 10px;
    display: block;
    // margin-top: 100px;
    /* border-color: rgb(121, 156, 156); */
    // box-shadow: 3px 3px 2px red;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    padding-bottom: 20px;
}

.RegisterFlexEnd {
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
    padding-top: 5px;
    // background-color: red;
}

.RegisterCancelButton {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: bold;
    background-color: white;
    border-width: 1px;
    width: 100px;
}

.RegisterCancelButton:hover {
    cursor: pointer;
    transition: 0.2s;
    background-color: $light-green;
}

.RegisterSendLinkButton {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    background-color: $lightened-light-green;
    border-width: 1px;
    width: 100px;

}

.RegisterSendLinkButton:hover {
    cursor: pointer;
    transition: 0.2s;
    background-color: $light-green;
}

.RegisterSpinnerDiv {
    margin-top: 5px;
    background-color: $lightened-light-green;
}