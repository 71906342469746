.sortByDivMyClaimWrapper {
    padding-bottom: 20px;
    display: flex;
    // position: relative;
    // width: 200px;
    // background-color: red;
}

.sortByDivMyPapers {
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    display: flex;
    min-width: 160px;
    justify-content: space-between;
    // height: 50px;
    align-items: center;
}

.countMyPapers {
    padding-bottom: 3px;
}

.claimStatsWidgetMyPapers {
    min-width: 100px;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $white-border;
    border-radius: 5px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
}