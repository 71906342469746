.JCEntryHome {
    font-family: Arial, Helvetica, sans-serif;   
    background-color: $background-grey;
    color: black;
    border-radius: 10px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    font-size: 100%;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: vertical;
    margin-left: 10px;
}

.JCEntryHome:hover {
    cursor: pointer;
}

.linkerDivPaper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-green;
    // background-color: green;
}

.JCEntryDivHome {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: sticky;

}

.JCEntryHome:hover {
    background-color: $lightened-light-green;
    transition: 0.2s;
}

.SVGFigureHome {
    // background-color: $background-grey;
    height: 18px;
    width: 18px;
    border-radius: 25%;
    margin-right: 10px;
    margin-bottom: 4px;
}

.NavLinkPaper {
    text-decoration: none;
    color: $dark-green;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    // background-color: red;
}

.majorContainerHome {
    background-color: white;
    border-color: $white-border;
    border-radius: 10px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    margin: auto;
    margin-top: 10px;
    max-width: 700px;
    padding: 15px;
    text-align: center;
}

.majorContainerFigureUpdate {
    width: 100%;
    border-radius: 10px;
    // background-color: blue;
}

.middleColumnHome {
    // background-color: yellow;
    max-width: 700px;
    width: 700px;
}

.majorContainerFlex {
    // max-width: 750px;
    // padding-right: 25px;
    // padding-left: 25px;
    // margin: auto;
    display: flex;
    justify-content: center;
    // background-color: red;
    padding-top: 10px;
}



.makeEntryHome {
    display: initial;
}

.makeEntryHomeSmall {
    display: none;

}

.showIt {
    height: 100px;
    width: 100%;
    background-color: red;
}

.smallFigureContainerHomeNoMore {
    background-color: white;
    color: black;
    border-radius: 10px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    font-size: 100%;
    margin-bottom: 10px;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    width: 100%;   
    padding-bottom: 15px;
    padding-top: 15px;
    position: sticky;
    display: flex;
    justify-content: center;
    text-align: center;
}

.smallFigureContainerHome {
    background-color: white;
    color: black;
    border-radius: 10px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    font-size: 100%;
    margin-bottom: 10px;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    width: 100%;   
    // padding-bottom: 15px;
    // position: sticky;
}

.smallFigureContainerHomeTest {
    background-color: white;
    color: black;
    border-radius: 10px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    font-size: 100%;
    margin-bottom: 10px;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    width: 100%;   
    // padding-bottom: 15px;
    // position: sticky;
}

.figureContainerHome {
    background-color: $background-grey;
    color: black;
    // border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    border-right: 0;
    border-left: 0;
    // // border-bottom: 0;
    // border-bottom-right-radius: 10px;
    // border-bottom-left-radius: 10px;
    // padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    // float: left;
    box-shadow: $light-shadow;
    // font-size: small;
}

.figureUpdatePaddingHome {
    padding: 15px;
    padding-bottom: 0px;
}

.topBarFigureUpdateHome {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
    position: relative;
    // background-color: yellow;
}

.followSVGSVGClaimUpdate {
    background-color: white;
    border-style: solid;
    border-width: 4px;
    border-color: $dark-green;
    width: 35px;
    height: 35px;
    padding: 2px;
    margin-right: 5px;
}

.followSVGSVGClaimUpdate:hover {
    cursor: pointer;
}

.followSVGSVGClaimUpdateClicked {
    background-color: $dark-green;
    border-style: solid;
    border-width: 4px;
    border-color: $dark-green;
    width: 35px;
    height: 35px;
    padding: 2px;
    margin-right: 5px;
}

.followSVGSVGClaimUpdateClicked:hover {
    cursor: pointer;
}

.input5ClaimUpdate {
    background-color: $button-grey;
    // border-color: $dark-green;
    border-radius: 27px;
    // border-width: 2px;
    // float: right;
    height:35px;
    // margin-bottom: 5px;
    // margin-right: 5px;
    // margin-top: 5px;
    max-width:35px;
    padding: 8px;
    width:35px;
}

.input5ClaimUpdate:hover {
    background-color: $button-grey-hover;
    cursor: pointer;
}

.descriptionHome {
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    padding-bottom: 10px;
    // border-radius: 5px;
}

.figureUpdateTitleHome {
    font-size: 18px;
    color: blue;
    text-decoration: none;
}

.figureUpdateClaimAnchorHome {
    color: blue;
    text-decoration: none;
}

.figureUpdateClaimAnchorHome:hover {
    text-decoration: underline;
}

.figureUpdateTitleHomeDiv {
    padding-bottom: 10px;
}

.figureUpdateTitleHome:hover {
    text-decoration: underline;
}

.paperUpdateTitleHomeDiv {
    padding-top: 10px;
    // padding-bottom: 10px;
}

.paperUpdateTitleHome:hover {
    text-decoration: underline;
}

.divAbove3PaperUpdate {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 20px;
    font-style: italic;
    padding-bottom: 10px;
}

.updateLine {
    // background-color: red;
    padding-bottom: 10px;
}

.line3Claim {
    // margin-bottom: 5px;
    margin-top: 5px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.giantSpacerHome {
    height: 400px;
    background-color: yellow;
    width: 100%;
}

.spacerUpdateTest {
    height: 500px;
    width: 100%;
    background-color: yellow;
    margin-top: 10px;
}

.whatsNewHeaderHome {
    text-align: center;
    padding-bottom: 10px;
}

.methodsHome {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;

    // background-color: rgb(142, 30, 30);
    // border-radius: 5px;
}

.rejectFigureHome {
    background-color: white;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 5px;
    width:7%;
}



.rejectFigureHomeClicked {
    background-color: $dark-green;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    width:7%;
    padding: 5px;
}

.acceptFigureHome {
    background-color: white;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 5px;
    width:7%;
}

.theFlexHome {
    // background-color: green;
    display: flex;
    justify-content: center;
    // width: 50%;
    // margin: 0 auto;
    // float: none;
    // background-color: green;
    // float: center;
    // border-radius: 10px;
    // padding: 5px;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 0px;
    // border-style: solid;
    // border-width: 1px 0px 0px 0px;
    // border-color: $grey-border;
    // margin-bottom: 10px;
    position: relative;
    // max-width: 250px;
    // border-radius: 15px;
}

.theFlexHomeTheory {
    // background-color: green;
    display: flex;
    justify-content: center;
    // width: 50%;
    // margin: 0 auto;
    // float: none;
    // background-color: green;
    // float: center;
    // border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 0px;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: $grey-border;
    // margin-bottom: 10px;
    position: relative;
    // max-width: 250px;
    // border-radius: 15px;
}

.comprehensivenessHome {
    padding-top: 60px;
    // background-color: red;
    width: 100%;
}

.theoryHomeDiv {
    padding-bottom: 30px;
    // background-color: aqua;
}

.centerFigureVotesHome {
    display: flex;
    justify-content: space-between;
    width: 250px;
}

.acceptFigureHomeClicked {
    background-color: $dark-green;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    width:7%;
    padding: 5px;
}

.openDescHome {
    background-color: white;
    background-color: $button-grey;
    border-radius: 27px;
    height: 27px;
    width: 27px;
    padding: 5px;
    left: 10px;
    top: 17px;
    position: absolute;
    // float: left;
    // margin-top: 10px;
    // margin-left: 10px;
}

.openDescHome:hover {
    cursor: pointer;
}

.openDescHomeFigure {
    background-color: white;
    background-color: $button-grey;
    border-radius: 27px;
    height: 27px;
    width: 27px;
    padding: 5px;
    left: 10px;
    top: 8px;
    position: absolute;
    // float: left;
    // margin-top: 10px;
    // margin-left: 10px;
}

.openDescHomeFigure:hover {
    cursor: pointer;
}

.TheoryBottomPaddingHome {
    padding-top: 10px;
    padding-bottom: 15px;
}



.line4Home {
    margin-bottom: 10px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.paddingLineHome {
    // padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.figDescHome {
    background-color: white;
    // border-bottom: 1px solid $button-grey;
    // border-top: 1px solid $button-grey;
    padding: 10px;
    padding-bottom: 0px;
    text-align: justify;
    border-radius: 10px;
    // text-justify: inter-word;
}

.openDescDiv {
    padding: 10px;
}


.flexClaimUpdate {
    display: flex;
    justify-content: space-evenly;

}

.flexClaimUpdate2 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.flexTheoryUpdate {
    display: flex;
    justify-content: space-evenly;
    padding-top: 15px;
}

.uploadedByHome {
    padding-top: 15px;
}

.confidenceAndEngagementClaim {
    // background-color: red;
    display: flex;
    background-color: $background-grey;
    border-radius: 10px;
    box-shadow: $heavy-shadow;
    padding-left: 10px;
    padding-right: 10px;
}

.localDateDivClaimUpdate {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    padding-top: 8px;
    font-size: 14px;
    font-style: italic;

}

.localDateDivClaimUpdate2 {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    padding-top: 8px;
    font-size: 14px;
    font-style: italic;
    padding-bottom: 8px;
}

.verticalCenterClaimUpdate {
    display: flex;
    align-items: center;
}



.smallFigureContainerUpdate {
    background-color: white;
    color: black;
    // border-radius: 20px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px 0 0 0;
    // padding: 2%;
    font-size: 100%;
    // margin-bottom: 10px;
    font-family: Helvetica;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    // height: 300px;
    // float: left;
    // box-shadow: $light-shadow;
    
    // font-size: small;
}

.smallFigureContainerUpdateLast {
    background-color: white;
    color: black;
    // border-radius: 20px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px 0 0 0;
    // padding: 2%;
    font-size: 100%;
    // margin-bottom: 10px;
    font-family: Helvetica;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    // height: 300px;
    // float: left;
    // box-shadow: $light-shadow;
    
    // font-size: small;
}

.titleDivClaimUpdate {
    display: flex;
    justify-content: center;
    padding-left: 5px;
    font-size: 14px;
    // background-color: aqua;
}

.titleDivClaimUpdate a {
    text-decoration: none;
    // font-weight: bold;
    color: blue;
}

.HomeNoWrap {
    white-space: nowrap;
}

.titleDivClaimUpdate a:hover {
    text-decoration: underline;
}

.testingMessage {
    font-size: 13px;
    padding-top: 5px;
    padding-left: 3px;
}


.verticalCenterClaimUpdate2 {
    display: flex;
    // align-items: center;
    // justify-content: center;
    // background-color: yellow;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;

}

.verticalCenterPaperUpdate {
    display: flex;
    // align-items: center;
    // justify-content: center;
    // background-color: yellow;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.anchorSpanPaperUpdate {
    padding-right: 20px;
    justify-content: start;
    // padding-top: 25px;
    text-align: left;
    // display: flex;
    // align-items: center;
    width: 100%;
    // background-color: red;
}

.paperUpdateAnchor {
    text-decoration: none;
    color: $dark-green;
    // background-color: red;
}

.paperUpdateAnchor:hover {
    text-decoration: underline;
}

.claimStatsWidgetPaperUpdate {
    min-width: 80px;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $white-border;
    border-radius: 5px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
}

.centerAlignTitleAndTypeUpdate {
    display: flex;
    align-items: center;
}

.figClaimTitleAndTypeUpdate {
    display: flex;
    // padding-bottom: 10px;
}

.HomeMessagesDiv {
    padding-top: 5px;
}

.blue {
    display: flex;
    align-items: center;
    // background-color: blue;
    padding-right: 10px;
    position: relative;
}

.openDescHome2 {
    background-color: white;
    background-color: $button-grey;
    border-radius: 27px;
    border-width: 1px;
    border-color: black;
    height: 27px;
    width: 27px;
    padding: 5px;
    // right: 10px;
    // top: 17px;
    // position: absolute;
    // float: left;
    // margin-top: 10px;
    // margin-left: 10px;
}

.openDescHome2:hover {
    cursor: pointer;
}


.optionsFigClaimUpdate {
    background-color: white;
    background-color: $button-grey;
    border-radius: 27px;
    border-width: 1px;
    border-color: black;
    height: 27px;
    width: 27px;
    padding: 5px;
    margin-right: 5px;
}

.optionsFigClaimUpdate:hover {
    background-color: $button-grey-hover;
    cursor: pointer;
}

.divAboveClaimUpdate {
    width: 100%;
    display: flex;
    position: relative;
}


.figClaimTypeSupport {
    font-style: bold;
    color: $light-green;
    // width: 150px; 
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    // white-space: nowrap;
    min-width: 150px;
}

.paperTitleFont {
    font-size: 14px;

}

.figClaimTypeContra {
    font-style: bold;
    color: $delete-red;
    // width: 150px; 
    // background-color: blue;
    display: flex;
    // white-space: nowrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    min-width: 150px;
    // font-size: large;
}


.paperTitleAndTypeUpdate {
    display: flex;
    // background-color: red;
}

.dropDownClaimUpdate {
    position: absolute;
    width: 250px;
    // margin-top: 85px;
    right: 0px;
    top: 25px;
    // padding-top: 10px;
    padding: 5px;
    // float: right;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    z-index: 1000;
}

.dropDownClaimUpdateHome {
    position: absolute;
    width: 250px;
    // margin-top: 45px;
    // margin-left: -210px;
    top: 45px;
    right: 0px;
    padding-top: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    z-index: 1000;
}

.divAbove2FigClaimUpdate {
    
    // height: 90px;
    // margin-bottom: 10px;
    // margin-top: 10px;
    width: 100%;
    // background-color: green;
    display: flex;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    // border-bottom: none;
    border-left: none;
    border-right: none;
    // padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 5px;
    // border-color: $button-grey-hover;
    border-bottom: 1px solid $button-grey;
    border-top: 1px solid white;
    // font-size: 10px;
    
}

.anchorNameFigClaimUpdate {
    text-decoration: none;
    color: blue;
    // float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 2px;
    font-size: 13px;
}

.addedByFigClaimUpdate {
    font-size: 13px;
}

.doiPaperHomeUpdate {
    // color: rgb(43, 81, 185);
    text-align: left;
    padding-top: 20px;
}


@media (max-width: 665px) {
    .makeEntryHome {
        display: none;
    }
    .makeEntryHomeSmall {
        display: block;
        padding-bottom: 10px;


    }
}