.figurePageLeft {
    border-color: rgb(121, 156, 156);
    background-color: white;
    // margin: auto;
    width: 60%;
    border-radius: 4px;
    // float: left;
    
    /* border-style: solid; */
    border-width: 1px;
    padding: 20px;
    padding-top: 0px;
    box-shadow: $heavy-shadow;
    min-height: 328px;
}


    // .figurePageLeft {
    // //     border-color: rgb(121, 156, 156);
    //     background-color: red;
    // //     margin: auto;
    //     width: 100%;
    // //     border-radius: 4px;
    // //     // float: left;
    // //     /* border-style: solid; */
    // //     border-width: 1px;
    // //     padding: 20px;
    // //     padding-top: 0px;
    // //     box-shadow: $heavy-shadow;
    // }

    // .majorContainer {
    //     display: block;
    //     background-color: red;
    // }

// @media only screen 
//     and (device-width : 414px) 
//     and (device-height : 896px) 
//     and (-webkit-device-pixel-ratio : 2) {

//     .figurePageRightContainer {
//         background-color: red;
//         width: 100%;
//         box-shadow: $heavy-shadow;
//     }

// }




.notFound {
    text-align: center;
    margin-top: 10%;
}


.majorContainer {
    max-width: 1200px;
    margin: auto;
    // background-color: red;
    display: flex;
}

.majorContainerSmall {
    max-width: 1200px;
    margin: auto;
    // background-color: red;
    // display: flex;
}



.figurePageLeftSmall {
    border-color: rgb(121, 156, 156);
    background-color: white;
    margin: auto;
    width: 100%;
    border-radius: 4px;
    float: left;
    /* border-style: solid; */
    border-width: 1px;
    padding: 20px;
    padding-top: 0px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
}

.figurePageRightSmall {
    // display:block;
    background-color: white;
    
    margin-top: 1%;
    width: 100%;
    max-height: 600px;
    float: left;
    border-radius: 4px;
    /* border-style: solid; */
    border-width: 1px;
    border-color: rgb(171, 183, 183);
    padding: 20px;
    padding-top: 0px;
    box-shadow: $heavy-shadow;
    
}


.critDelete {
    background-color: rgb(228, 92, 92);
    color: white;
    max-height: 50px; 
    border-radius: 50%;
    float: right; 
    // max-width: 50px; 
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    
}

.critDelete:hover {
    background-color: rgb(233, 152, 152);
}


.addDiv {
    background-color: rgb(98, 189, 184);
    color: white;
    max-height: 50px; 
    border-radius: 50%;
    // float: right; 
    // max-width: 50px; 
    width: 34px;
    height: 34px;
    cursor: pointer;
    display: flex;
    // align-items: center;
    // justify-content: center;
    // text-align: center;
    padding: 6px;
}

.addDiv:hover {
    background-color: $lightened-light-green;
}

.addCritDiv {
    display: flex;
    margin-bottom: 15px;
}

.centerTheAddButton {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

#toHide {
    text-align: center;
}

.figurePageRightContainer {
    padding-left: 10px;
    width: 40%;
}

.figurePageRight {
    // display:block;
    background-color: white;
    // margin-left: 1%;
    width: 100%;
    max-height: 700px;
    // float: left;
    border-radius: 4px;
    /* border-style: solid; */
    border-width: 1px;
    border-color: rgb(171, 183, 183);
    padding: 20px;
    padding-top: 0px;
    box-shadow: $heavy-shadow;
    overflow: auto;
}



.line {
    // margin-top: 10px;
    height: 1px;
    width: 100%;
    border-color: rgb(156, 154, 154);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.line2 {
    margin-bottom: 10px;
    height: 1px;
    width: 100%;
    border-color: rgb(156, 154, 154);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.line3 {
    margin-bottom: 10px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.line3CriticismsFigure {
    margin-bottom: 10px;
    height: 1px;
    width: 100%;
    border-color: rgb(224, 222, 222);
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.formDiv {
    margin-top: 10px;
}

.figurePageRight1 {
    // display:block;
    background-color: white;
    margin-left: 1%;
    width: 39%;
    max-height: 500px;
    float: left;
    margin-bottom: 1%;
    border-radius: 4px;
    /* border-style: solid; */
    border-width: 1px;
    border-color: $white-border;
    padding: 20px;
    box-shadow: $heavy-shadow;
    overflow: auto;
    
}

.figureCritRight {
    // display:block;
    background-color: white;
    // margin-left: 1%;
    // width: 39%;
    max-height: 600px;
    // margin-top: 1%;
    // float: left;
    border-radius: 4px;
    /* border-style: solid; */
    border-width: 1px;
    border-color: rgb(171, 183, 183);
    box-shadow: $heavy-shadow;
    padding: 20px;
    padding-top: 0px;
    margin-top: 10px;
    overflow-y: auto;
}

.associatedClaimLink {
    color: blue;
    text-decoration: none;
}

.associatedClaimLink:hover {
    text-decoration: underline;
}
.anchor {
    text-decoration: none;
    color: blue;
}

.anchor:hover {
    cursor: pointer;
}

.loginNotice {
    text-align: center;
    font-weight: bold;
    height: 80px;
    
    font-size: 20px;
    padding-top: 20px;
}

.addFigureLogin {
    text-align: center;
    font-weight: bold;
    height: 80px;
    
    font-size: 20px;
    padding-top: 200px;
}

.anchorName {
    text-decoration: none;
    color: blue;
    float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px;
} 

.anchor:hover {
    text-decoration: underline;
    color: blue;
}

.addedByFigure {
    // text-align: left;
    // padding-left: 10px;
    font-size: 14px;
    // font-size: 15px;
    // height: 50px;
    // display: flex;
    // align-items:center;
    // justify-content:center;
    // vertical-align: middle;
    // background-color: brown;
}

.divAbove2Figure {
    
    // height: 90px;
    // margin-bottom: 10px;
    // margin-top: 10px;
    width: 100%;
    // background-color: green;
    display: flex;
    align-items: center;
    
}

.criticismTimeStamp {
    width: 100%;
    display: flex;
    align-items: center;
    // padding: 2px;
    padding-top: 8px;
    font-size: 12px;
    padding-left: 5px;
    font-style: italic;

}

.criticismTimeStampFigure {
    width: 100%;
    display: flex;
    align-items: center;
    // padding: 2px;
    padding-top: 12px;
    font-size: 12px;
    padding-left: 5px;
    font-style: italic;

}

.critDiv {
    width: 100%;
    // float: left;
    display: flex;
    
    margin-bottom: 10px;
}

.selection {
    margin-top: 3%;
    margin-left: 5px;
    background-color: $background-grey;
    border-radius: 4px;
    box-shadow: $light-shadow;
    border-color: $grey-border;
}

.crit {
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    width: calc(100% - 39px);
    float: left;
    box-shadow: $light-shadow;
    font-size: small;
}

.critUnlogged {
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    width: 100%;
    float: left;
    box-shadow: $light-shadow;
}

.critButtons {
    // background-color: aqua;
    height: 70px;
    width: 34px;
    float: left;
    margin-left: 5px;
}

#newCrit {
    width: calc(100% - 44px); 
    // float: left;
    font-family: Arial, Helvetica, sans-serif;   
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    // padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: vertical;
}

.PlusIconCrit {
    // max-width: 6%;
    margin-left: 5px;
    width: 34px;
    
}

#doi {
    // color: rgb(43, 81, 185);
    padding-bottom: 8px;
}

#input1 {
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    border-radius: 27px;
    float: right;
    width:7%;
    padding: 6px;
    background-color: $delete-red;
}

#input1:hover {
    background-color: $lightened-delete-red;
    cursor: pointer;
}


#input2 {
    background-color: $delete-red;
    border-radius: 27px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 10px;
    width:7%;
}

#input3 {
    background-color: white;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 5px;
    width:7%;
}



#input3Clicked {
    background-color: $dark-green;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    width:7%;
    padding: 5px;
}

// #input3:hover {
//     background-color: $light-green;
//     cursor: pointer;
// }
// #input3Clicked:hover {
//     background-color: $light-green;
//     cursor: pointer;
// }

#input2::before {
    content: attr(data-tooltip)
}

#input4 {
    background-color: $light-green;
    border-radius: 27px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 6px;
    width:7%;
}

#input4:hover {
    background-color: $lightened-light-green;
    cursor: pointer;
}

#input5 {
    background-color: $button-grey;
    // border-color: $dark-green;
    border-radius: 27px;
    // border-width: 2px;
    float: right;
    height:35px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-top: 5px;
    max-width:35px;
    padding: 8px;
    width:35px;
}

#input5:hover {
    background-color: $button-grey-hover;
    cursor: pointer;
}

#svg {
    max-height:50px;
    max-width:50px;
}

#input6 {
    background-color: white;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 3px;
    width:7%;
}



#input6Clicked {
    background-color: $dark-green;
    border-color: $dark-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    width:7%;
    padding: 3px;
}

#input6:hover {
    background-color: $light-green;
    cursor: pointer;
}
#input6Clicked:hover {
    background-color: $light-green;
    cursor: pointer;
}




.floatRight {
    float: right;
    
}

.margin-right {
    margin-right: 265px;
}

.dropDown1 {
    position: absolute;
    width: 300px;
    margin-top: 40px;
    margin-right: 2000px;
    
    border-radius: 5px;
    background-color: $dark-green; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 3px;
    border-style: solid;
    
}

.dropDown1 a {
    color: white;
    text-decoration: none;
}

.dropDown1 a:hover {
    color: $light-green;
    text-decoration: underline;
    cursor: pointer;
}

.theFlex {
    // background-color: green;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    float: none;
    // background-color: green;
    float: center;
    border-radius: 10px;
    padding: 5px;
    // border-style: solid;
    border-width: 2px;
    border-color: $dark-green;
    margin-bottom: 5px;
    max-width: 250px;
}

.figureContainer {
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    // padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    // float: left;
    box-shadow: $light-shadow;
    // font-size: small;
}

#Checkjpeg {
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    float:right;
    margin-right:0.5%;
    width:7%;
}

.figureShow {
    margin-top: 20px;
}


.figureShowImage {
    cursor: pointer;
    border-style: solid;
    border-bottom: black;
    border-width: 1px;
    border-color: #F5F7FA;
    // border-radius: 5px;
    background-color: white;
    width: 100%;
    // max-height: 500px;
}

.modalBackground {
    // cursor: pointer;
    position: fixed;
    z-index: 9001;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    max-height: 100vh;
    overflow-y: auto;
    text-align: center;
    // display: none;
}

.modalBackgroundDelete {
    // cursor: pointer;
    position: fixed;
    z-index: 9002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // margin-right: 50px;
    // position: relative;
    // background-color: rgba(0, 0, 0, .8);
    max-height: 100vh;
    // overflow-y: auto;
    display: flex;
    // text-align: center;
    align-items: center;
    justify-content: center;
    // display: none;
}

.blackBackground {
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    max-height: 100vh;
}

.modalBackground.active {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;

}

.modalBackground img {
    width: 90%;
    padding: 4px;
    background-color: black;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5%;
    // margin-left: 5%;
}

#description {
    padding: 5px;
    background-color: white;
    border-radius: 5px;
}

#methods {
    padding: 5px;
    background-color: white;
    border-radius: 5px;
}



.Down {
    float: right;
    width: 100%;
}

.Up {
    float: right; 
    width: 100%;
    padding:5px;
    border-radius: 50%;
    background-color: white;
    border-width: 2px;
    border-color: #002c2c;
    border-style: solid;
}

.UpClicked {
    float: right; 
    width: 100%;
    padding:5px;
    border-radius: 50%;
    background-color: #002c2c;
    border-width: 2px;
    border-color: #002c2c;
    border-style: solid;
}

.Up:hover {
    background-color: rgb(136, 207, 204);
    cursor: pointer;
}

.UpClicked:hover {
    background-color: rgb(136, 207, 204);
    cursor: pointer;
}


.Down {
    float: right; 
    width: 100%;
    padding:5px;
    border-radius: 50%;
    background-color: white;
    border-width: 2px;
    border-color: #002c2c;
    border-style: solid;
    margin-top: 1px;
}

.DownClicked {
    float: right; 
    width: 100%;
    padding:5px;
    border-radius: 50%;
    background-color: #002c2c;
    border-width: 2px;
    border-color: #002c2c;
    border-style: solid;
    margin-top: 1px;
}

.Down:hover {
    background-color: rgb(136, 207, 204);
    cursor: pointer;
}

.DownClicked:hover {
    background-color: rgb(136, 207, 204);
    cursor: pointer;
}

#postAs {
    // background-color: green;
    // width: calc(100% - 39px); 
    // float: left;
    display: flex;
    
    padding-bottom: 10px;
}

#postAsSmall {
    padding-bottom: 10px;
    display: flex;
    font-size: 14px;
}

.tags {
    // border-style: solid;
    border-color: $grey-border;
    border-width: 2px;
    // border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    // background-color: rgb(221, 26, 146);
}

.checkBoxDiv {
    width: 100%;
    // background-color: yellow;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.checkBoxDivFirst {
    width: 100%;
    // background-color: yellow;
    // margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FigureDiv1 {
    display: flex;
    align-items: center;
}

.postAsSpan {
    // background-color: cyan;
    // padding-top: 2px;
    // display: flex;
    // align-items: center;
}

#tagSVG {
    background-color: $button-grey;
    border-radius: 50%;
    border-width: 2px;
    padding: 3px;
}

#tagSVG:hover {
    cursor: pointer;
}

.label {
    margin-left: 4px;
    margin-top: 3px;
    // margin-bottom: 20px;
}

.checkBox {
    padding-top: 2px;
    height: 18px;
    width: 18px;
    vertical-align: bottom;
}

.checkBox1 {
    padding-top: 2px;
    height: 18px;
    width: 18px;
    margin-right: 4px;
    vertical-align: bottom;
    // margin-bottom: 5px;
}

.postAsDiv {
    // width: 100px;
    // background-color: red;
    // position: absolute;
    display: flex;
}

.tagsSpan {
    margin-left: 10px;
    display: flex;
    align-items: center;
    // float: right;
}

.firstCheck {
    // background-color: yellow;
    // width: 200px;
}

.red {
    background-color: red;
    float: right;
}



.green {
    background-color: green;
}

.deleteModal {
    background-color: white;
    width: 500px;
    height: 200px;
    padding: 30px;
    padding-top: 10px;
    z-index: 1004;
    position: fixed;
    top: calc(50% - 100px);
    left: calc(50% - 250px);
    // height: 300px;
    // margin: auto;
    // margin-top: 20%;
    // display: flex;
    // align-items: center;
    justify-content: center;
    // text-align: center;
    border-radius: 10px;
}

.redFont {
    color: $delete-red;
    margin-left: 10px;
    text-decoration: underline;
}

.redFontFigure {
    color: $delete-red;
    // margin-left: 10px;
    text-decoration: underline;
    width: 100%;
    margin-top: 10px;
}

.redFontFigure2 {
    color: $delete-red;
    // margin-left: 10px;
    text-decoration: underline;
    width: 100%;
    padding-bottom: 10px;
}

.redFontFigure3 {
    color: $delete-red;
    text-decoration: underline;
}


.deleteModal h1 {
    // margin-left: 20px;
}

.deleteButton {
    margin-top: 20px;
    float: right;
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $dark-green;
}

.deleteButton:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.withinDelete {
    display: flex;
    // align-items: center;
    // background-color: blue;
    // justify-content: center;
    margin-top: 20px;
}

.deleteX {
    height: 30px;
    width: 30px;
    border-style: solid;
    border-width: 1px;
    border-color: $dark-green;
    border-radius: 50%;
    padding: 5px;
    float: right;
}

.deleteX:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.deleteFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-color: white;
    border-bottom: $dark-green;
    border-width: 1px;
    border-style: solid;
}

.associatedClaimsList {
    // background-color: red;
    padding-top: 10px;
    padding-bottom: 10px;
}

.noCriticismsYetFigure {
    padding-top: 30px;
    padding-bottom: 30px;
}

.critButtonsFigure {
    border-color: $white-border;
    caret-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.critFigureButton1 {
    border-color: $white-border;
    padding: 2px;
    padding-right: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: white;
    // margin-right: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: 80%;
    caret-color: transparent;
    // width: 70px;
}

.critFigureButton1:hover {
    cursor: pointer;
    background-color: $lightened-delete-red;
}

.critFigureButtonUp {
    border-color: $white-border;
    padding: 2px;
    padding-right: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: white;
    // margin-right: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: 80%;
    caret-color: transparent;
    // width: 70px;
}

.critFigureButtonUp:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.critFigureButton2 {
    border-color: $white-border;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    caret-color: transparent;
}

.critFigureButton2:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.topBarFigure {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
    position: relative;
}

.centerVertical {
    display: flex;
    align-items: center;
}

.verticalStrecthFigure {
    // height: 100%;
}

.dropDownFigure {
    position: absolute;
    width: 250px;
    margin-top: 45px;
    margin-left: -210px;
    padding-top: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.fromPaper {
    font-size: 17px;
    display: flex;
    justify-content: baseline;
    padding-bottom: 10px;
    padding-top: 10px;
}

.fromPaper2 {
    font-size: 17px;
    display: flex;
    justify-content: baseline;
    padding-bottom: 10px;
    padding-top: 10px;
}



@media (max-width: 800px) {
    .figurePageRightContainer {
        padding-left: 0px;
        width: 100%;
        padding-top: 10px;
    }

    .figurePageLeft {
        width: 100%;

        padding-top: 5px;
    }

    .majorContainer {
        display: block;
    }
}


.reportCriticismModal {
    background-color: white;
    width: 500px;
    padding: 30px;
    padding-top: 10px;
    z-index: 1004;
    // height: 300px;
    // margin: auto;
    // margin-top: 20%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // text-align: center;
    border-radius: 10px;
}

.removeModalFlow {
    position: absolute;
    // background-color: aqua;
    width: 100%;
    height: 100%;

}

.centerReportModal {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: aqua;
    width: 100%;
    height: 100%;
}


.FigureReportCriticismText {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;   
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    margin-top: 5px;
    // padding: 2%;
    font-size: 100%;
    min-height: 150px;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: vertical;
}


.FigureReportCriticismButtonWrapper {
    display: flex;
    padding-top: 5px;
    justify-content: end;
    align-items: center;
}

.FigureReportSubmitted {
    padding-top: 30px;
    padding-bottom: 30px;
}


.FigureReportCritModal {
    background-color: white;
    width: 500px;
    padding: 30px;
    padding-top: 10px;
    z-index: 1004;
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% - 250px);
    // height: 300px;
    // margin: auto;
    // margin-top: 20%;
    // display: flex;
    // align-items: center;
    justify-content: center;
    // text-align: center;
    border-radius: 10px;
}