.AddClaimMajorContainer2 {
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    margin: auto;
    margin-top: 10px;
    max-width: 1000px;
    padding: 20px;
    // padding-top: 0px;
    text-align: center;
}

.AddClaimTitle {
    width: 80%; 
    height: 60px;
    border-radius: 10px;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 15px;
    background-color: $background-grey;
}

.checkBoxDivAddClaim {
    width: 100%;
    // background-color: yellow;
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.AddClaimTitleDiv {
    display: flex;
    justify-content: center;
}

.AddClaimTitleHeader {
    text-align: left;
    width: 80%;
    // background-color: aquamarine;
}


.extractedDivAddClaim {
    // background-color: red;
    display: flex;
    width: 80%;
    margin: auto;
    align-items: center;
    padding-top: 10px;
}

