.ApplyAnchorNamingConvention {
    color: blue;
    text-decoration: underline;
}

.ApplyAnchorNamingConvention:hover {
    cursor: pointer;
}

.ApplyHalfTextArea {
    width: 100%; 
    height: 45px;
    border-radius: 10px;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 10px;
    background-color: $background-grey;
}

.ApplyFullTextArea {
    width: 100%; 
    height: 100px;
    border-radius: 10px;
    resize: none;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    border: none;
    padding: 10px;
    background-color: $background-grey;
}

.ApplyUpload {
    display: flex;
    padding-bottom: 10px;
}

.ApplyTitleHeader {
    text-align: left;
    padding-bottom: 10px;
    padding-top: 20px;
    
}

.ApplySubmitNewTag {
    width: 200px;
    // margin-top: 100px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    
    border-radius: 5px;
    color: white;
    background-color: #002c2c;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;

    // box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.ApplySubmitNewTag:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.ApplySubmitNewTagSubmitted {
    width: 200px;
    // margin-top: 100px;
    box-shadow: rgb(100, 100, 111) 0px 0px 1.5px 0px;
    
    border-radius: 5px;
    color: white;
    background-color: $light-green;
    font-family: Arial;
    font-size: 20px;
    height: 50px;
    // position: absolute;
    cursor: pointer;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: rgb(100, 100, 111) 0px 2px 10px 0px;
}

.ApplyApplied {
    padding-top: 200px;
    padding-bottom: 200px;
}

.ApplyRequestSubmitted {
    padding-top: 100px;
    padding-bottom: 100px;
}

.ApplyResetRequest {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color: $light-green;
    border-width: 0px;
    margin-top: 10px;
    // width: 200px;
}

.ApplyResetRequest:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
    transition: 0.2s;
}