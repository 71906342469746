.theHead {
    background: $dark-green;
    color: white;
    
    border-color:rgb(121, 120, 119);
    box-shadow: rgb(100, 100, 111) 0px 0px 2px 0px;
    height: 75px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 9000;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
  }

.logoDivHeader {
    display: flex;
    align-items: center;
}

.randomLarge {
    height: 2000px;
    width: 100%;
    background-color: aqua;
}

.randomLarge2 {
    height: 2000px;
    width: 100%;
    background-color: red;
}
.newrow {
    // margin-left: 2%;
    // margin-right: 2%;
    // background-color: yellow;
    height: 90px;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
    align-items: center;
}

.JournalClub {
    padding-left: 10px;
    // background-color: green;
    display: flex;
    min-width: 180px;
    align-items: center;
}

.JournalClub2 {
    padding-left: 10px;
    // background-color: green;
    display: flex;
    min-width: 180px;
    align-items: center;
}

.hidden {
    display: none;
}



.spacerHeader {
    width: 15%;
    background-color: brown;
    
}

.spacerHeader2 {
    width: 15%;
    background-color: red;
    
}


.navigationBar {
    // width: 20%;
    // background-color: cadetblue;
    display: flex;
    justify-content: space-between;
    width: 100%;
    // max-width: 2000px;
}

.linkerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    // background-color: green;
    // padding-left: 15px;
    // padding-right: 15px;
    // background-color: blue;
}

.linkerDiv:hover {
    cursor: pointer;
}

.linkerDivUnder {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: white;
    text-decoration: none;
    border-width: 3px 0px;
}

.linkerDivUnder:hover {
    cursor: pointer;
}


.theNavLink {
    text-decoration: none;
    // text-decoration: underline;
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    // background-color: red;
}



.NavUnderline {
    height: 1px;
    width: 100%;
    position: fixed;
    background-color: white;
}

.theNavDiv {
    display: flex;
    justify-content: space-between;
    // background-color: yellowgreen;
    min-width: 510px;
    max-width: 600px;
    width: 40%;
}

.theNavDiv2 {
    display: flex;
    justify-content: space-between;
    // background-color: yellowgreen;
    // min-width: 510px;
    // width: 40%;
}




#profileSVG {
    background-color: $dark-green;
    height: 18px;
    width: 18px;
    border-radius: 25%;
    margin-right: 10px;
    margin-bottom: 4px;
}

#theorySVG {
    background-color: $dark-green;
    height: 22px;
    width: 22px;
    border-radius: 25%;
    margin-right: 7px;
    margin-bottom: 4px;
}



.div2 {
    float:right;
    padding-top: 28px;
    padding-right: 1%;
    
}
.div3 {
    float:right;
    padding-top: 28px;
    padding-right: 1%;
    
}

.password1 {
    color: black;
    width: 200px;
    height: 35px;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 15px;
    margin-top: 3px;
    font-weight: bold;
}

.email1 {
    color: black;
    width: 200px;
    height: 35px;
    font-size: 125%;
    border-radius: 5px;
    border: none;
    text-indent: 10px;
    font-size: 15px;
    margin-top: 3px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.logInDiv {
    margin-top: 7px;
    display: flex;

}

.incorrectHeader {
    font-size: 70%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-left: 15px;
    color: rgb(231, 111, 111);

}

.hello {
    // background-color: red;
    // height: 90px;
    float: right;
    width: 280px; 
}

.hello1 {
    // background-color: blue;
    // height: 90px;
    float: right;
    width: 210px; 
}

.hidden {
    display: none;
    
}


.ButtonLogin2 {
    float: right;
    color: white;
    margin-top: 3px;
    height: 35px;
    width: 70px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #002c2c;
    border-color: rgb(99, 188, 184);
    border-width: 3px;
}

.ButtonLogin2:hover {
    background-color: rgb(99, 188, 184);
    transition: 0.2s;
}

.loginHeader {
    color: white;
    text-decoration: none;
    font-weight: bold;
}




#search-text1 {
    /* position: absolute; */
    margin-left: 3%;
    margin-top: 25px;
    width: 70%;
    height: 40px;
    font-size: 120%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
}



.navigator {
    width: 7%;
    min-width: 75px;
    float: right;
    margin-top: 35px;
    list-style: none;
    // background-color: grey;
}

.navigatorLast {
    width: 7%;
    min-width: 80px;
    float: right;
    margin-top: 35px;
    list-style: none;
    // background-color: grey;
    margin-right: 2%;
}

.navigator li a {
    float: right;
    color: white;
    font-size: 125%;
    text-decoration: none;
    /* background-color: green; */
}

.navigatorLast li a {
    float: right;
    color: white;
    font-size: 125%;
    text-decoration: none;
    
    /* background-color: green; */
}

.navigator li a:hover{
    float: right;
    color: rgb(99, 188, 184);
    font-size: 125%;
    transition: 0.2s;
    text-decoration: none;
    /* background-color: green; */
}

.navigatorLast li a:hover{
    float: right;
    color: rgb(99, 188, 184);
    font-size: 125%;
    transition: 0.2s;
    text-decoration: none;
    /* background-color: green; */
}

#HeaderLogoImg {
    border-radius: 10px;
    height: 70px;
}



.theLogo {
    // background-color: red;
    display: flex;
    // justify-content: center;
    align-items: center;
    // width: 200px;
    // height: 500px;
}

.searchBar2 {
    background-color: blue;
}


.dropDown {
    position: absolute;
    z-index: 1000;
    width: 220px;
    top: 65px;
    // right: 10px;
    right: 10px;
    padding: 5px;
    // left: 0;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    
}

.optionsMenuDiv {
    // background-color: aquamarine;
    // margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 80%;
    display: flex;
    justify-content: left;
    align-items: center;
  
}

.optionsMenuDiv:hover {
    background-color: $light-green;
    transition: 0.2s;
}

.dropDown a {
    color: $dark-green;
    text-decoration: none;
}

.dropDown a:hover {
    // color: rgb(99, 188, 184);
    // text-decoration: underline;
    cursor: pointer;
}


.downIcon svg {
    width: 15px;
    height: 15px;
    
}


.downIcon {
    text-decoration: none;
    border-width: 10px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downIcon:hover {
    color: white;
    text-decoration: none;
    border-radius: 50%;
    background-color: rgb(99, 188, 184);
    padding: 10px;
    margin: 2px;
    transition: 0.3s;
    
}

.theNav {
    // float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 10px;
    // background-color: aqua;
}

.theNav2 {
    display: none;
}

.logInForm {
    width: 600px;
    // background-color: aqua;
}

.nav-item {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: rgb(99, 188, 184);
    border-width: 10px;
    // margin-top: 20px;
}

.menuBorder {
    
}



.centerSearchResults {
    display: flex;
    justify-content: center;
}

.SearchBarSmallDiv2 {
    
    width: 45%;
    // min-width: 1200px;
    background-color: blue;
}
.SearchBarLast {
    width: 100%;
    // background-color: yellow;
}

.theSpan {
    // position: relative;
    // background-color: green;
    // display: inline-block;
    // width: 35%;
    margin-left: 30px;
    max-width: 850px;
    // padding-left: 30px;
    // align-items: bottom;
}

.searchBarDivHeader {
    display: flex;
    justify-content: center;
    // background-color: red;
    padding-top: 17px;
}

.divHeaderHolder1 {
    flex-grow: 1;
    // display: flex;
    // align-items: center;
}

.size {
    width: 20px;
    // margin-right: 10px;
    // float: right;
    // background-color: red;
    display: flex;
    justify-content: center;
    // padding-top: 57px;
    // padding-left: 20px;
}

.theSearchSVG {
    position: relative;
    // bottom: 30px;
    // margin-left: 100px;
    // float: right;
    // left: 10px;
    right: 30px;
    // z-index: 5000;
    // align-items: right;
}

#search-text {
    /* position: absolute; */
    // margin-left: 3%;
    margin-top: 17px;
    width: 100%;
    height: 40px;
    font-size: 120%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
}

.search-text {
    /* position: absolute; */
    // margin-left: 3%;
    // margin-top: 17px;
    width: 100%;
    height: 40px;
    font-size: 120%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    padding-right: 40px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
    max-width: 850px;
}

.HeaderSearchBarText {
    /* position: absolute; */
    // margin-left: 3%;
    // margin-top: 17px;
    width: 100%;
    height: 40px;
    font-size: 100%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 8px;
    font-weight: 300;
    padding: .8rem;
    padding-right: 40px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
    max-width: 850px;
}

.HeaderSearchBarText:focus {
    outline: none;
    
    border-color: $light-green;
    border-width: 2px;
}

.spacerDiv {
    position: absolute;
}

.searchBarDivs {
    text-decoration: none;
    font-size: 15px;
}

.searchBarDivs a {
    text-decoration: none;

}

.searchBarDivs a:hover {
    text-decoration: underline;
}

.searchBarDivs a:visited {
    color: blue;
}

.searchBarClickDiv {
    font-size: 15px;
    // background-color: aqua;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    display: flex;
    
}

.searchBarClickDiv:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
    transition: 0.2s;
}

.HeaderSearchSVG {
    // background-color: $dark-green;
    height: 25px;
    width: 25px;
    border-radius: 25%;
}

.HeaderSVGDiv {
    display: flex;
    align-items: center;
    justify-content: left;
    min-width: 40px;
    // background-color: red;
}

.beingSearched {
    position: relative;
    width: 100%;
    left: 0;
    z-index: 2000;
    font-size: 120%;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: $white-border;
    border-radius: 8px;
    font-weight: 300;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: $heavy-shadow;
    background-color: white;
    max-width: 850px;
}

.HeadingsLargeDesc {
    font-weight: bold;
    // background-color: yellow;
}

.HeadingsSmallDesc {
    padding-top: 5px;
    color: rgb(96, 94, 94);
}



#seeBackground {
    // background-color: green;
    float: left;
    margin-bottom: 100px;
}

.SearchBarSmallDiv3 {
    display: none;
}

.loadingScreen {
    display: flex;
    // background-color: red;
    color: $dark-green;
    justify-content: center;
    // text-align: center;
    align-items: flex-end;
    height: 300px;
    font-size: 30px;
}

.loadingBarParent {
    padding-top: 15px;
    display: flex;
    justify-content: center;
}

.block1 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: $dark-green;
}

.block2 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: white;
    animation: loading 0.5s ease forwards;
    // background-color: $dark-green;
}

@keyframes loading {
    100% {
        background-color: $dark-green;
    }
}

.block3 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: white;
    animation: loading 0.5s 0.5s ease forwards;
}

.block4 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: white;
    animation: loading 0.5s 1s ease forwards;
}

.block5 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: white;
    animation: loading 0.5s 1.5s ease forwards;
}

.block6 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: white;
    animation: loading 0.5s 2s ease forwards;
}

.block7 {
    width: 30px;
    height: 5px;
    margin-left: 5px;
    background-color: white;
    animation: loading 0.5s 2.5s ease forwards;
}



.spacerHeader1 {
    display: none;
}

.randomHeaderSpacerDiv3 {
    display: none;
}


@media (max-width: 1485px) {
    .spacerHeader{
        display: none;
    }
    .theNavDiv {
        // width: auto;
    }
    

}


@media (max-width: 595px) {
    .theHead {
        background: $dark-green;
        color: white;
        border-color:rgb(121, 120, 119);
        box-shadow: rgb(100, 100, 111) 0px 0px 2px 0px;
        height: 75px;
        display: flex;
    }
}

@media (max-width: 925px) {
    .logInForm {
        max-width: 500px;
    }
}

@media (max-width: 1275px) {
    .SearchBarSmallDiv2 {
     display: none;
    }

    

    .SearchBarLast {
        display: none;
        // width: 45%;
        // display: flex;
        // max-width: 900px;
        // margin-left: 230px;
    }

    .navigationBar {
        justify-content: flex-end;
    }
}


@media (max-width: 820px) {
    .JournalClub {
        display: none;
    }

}

@media (max-width: 580px) {
    .linkerDiv {
        // display: none;
    }
    .linkerDivUnder {
        // display: none;
    }
    .JournalClub {
        padding-left: 10px;
        // background-color: green;
        display: flex;
        min-width: 180px;
        align-items: center;
    }

    .theNavLink {
        // background-color: rgb(99, 188, 184);
        // width: 0px;
        display: none;
    }
    .theNav {
        float: right;
    }
    .theNavDiv {
        // display: none;
        // justify-content: space-between;
        // background-color: red;
        min-width: 0px;
        // width: 10px;
    }
}

@media (max-width: 645px) {
    

    
    .theHead {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: 2000px) {
    // .spacerHeader{
    //     display: none;
    // }

    // .spacerHeader2{
    //     display: none;
    // }
    .navigationBar {
        // width: 20%;
        display: flex;
        justify-content: space-between;
        // width: 100%;
        // width: 500px;
        // position: relative;
        // right: 0;
    }

    .randomHeaderSpacerDiv3 {
        display: block;
        width: 366.16px;
        height: 100%;
        // background-color: blue;
    }


    // .spacerHeader1 {
    //     display: inline;
    //     width: 10%;
    //     background-color: yellow;
    // }

    .SearchBarLast {
        display: flex;
        // max-width: 900px;
        // margin-left: 230px;
    }
    
    .theHead {
        background: $dark-green;
        color: white;
        border-color:rgb(121, 120, 119);
        box-shadow: rgb(100, 100, 111) 0px 0px 2px 0px;
        height: 75px;
        display: flex;
        justify-content: space-between;
        
    }
    // .SearchBarSmallDiv3 {
    //     // position: relative;
    //     // right: 50%;
    //     width: 45%;
    //     display: inline;
    //     max-width: 900px;
    //     margin-left: 230px;
    //     // min-width: 1200px;
    //     // background-color: blue;
    // }
    // .SearchBarSmallDiv2 {
    //     display: none;
    // }
}


@media (max-width: 1530px) {
    .spacerHeader2{
        display: none;
    }
    .theNavDiv {
        // width: auto;
    }
    

}

@media (max-width: 800px) {
    .JournalClub2 {
        display: none;
    }

}

@media (max-width: 615px) {
    .JournalClub2 {
        display: block;
    }
    .logInForm{
        display: none;
    }
}