.anchorAbout {
    text-decoration: none;
    color: blue;
    padding-left: 5px;
}


.aboutText {
    padding-top: 10px;
    padding-left: 10px;
    text-align: center;
}

.aboutTextH2 {
    padding-top: 30px;
    // padding-left: 10px;
    // text-align: center;
    font-style: italic;
}

.centerWrapperAbout {
    display: flex;
    justify-content: center;
}

.loginAbout {
    text-align: center;
    font-weight: bold;
    // height: 80px;
    
    font-size: 23px;
    padding-top: 30px;
    padding-bottom: 50px;
}

.containerAbout {
    max-width: 100rem;
    padding: 2.6rem;
    padding-top: 0rem;
    padding-bottom: 2.6rem;
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
}

.paragraphsAbout {
    line-height: 19px;
    font-size: 95%;
}

.exampleAnchorAbout {
    color: blue;
    text-decoration: none;
}

.exampleAnchorAbout:hover {
    text-decoration: underline;

}

.aboutSubheaderAnon {
    padding-top: 35px;
    // padding-left: 10px;
    // text-align: center;
    font-style: italic;
    // background-color: aqua;
    padding-bottom: 7px;
}

.ApplyAnonLink {
    color: blue;

}

.ApplyAnonLink:hover {
    cursor: pointer;
}

.ApplyAnonHeader {
    display: flex;
}

.ApplySmallInfoIcon {
    padding-top: 2px;
    padding-left: 2px;
}

.ApplySmallInfoIcon:hover {
    cursor: pointer;
}