




.containerMyFigures {
    width: 100rem;
    height: 100rem;
    padding: 1.3rem;
    text-align: center;
    padding-top: 0rem;
    padding-bottom: 2.6rem;
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
}



.deleteButtonMyFigure {
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $dark-green;
    height: 40px;
    width: 100px;
}

.deleteButtonMyFigureClicked {
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $lightened-light-green;
    height: 40px;
    width: 100px;
}
.deleteButtonMyFigure:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}