

.claimShow {
    margin-top: 20px;
}

.majorContainer1 {
    background-color: white;
    border-color: $white-border;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    margin: auto;
    margin-top: 10px;
    max-width: 1500px;
    padding: 20px;
    text-align: center;
}

.claimTitleandStats {
    // background-color: blueviolet;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.h1ClaimTitle {
    padding-left: 30px;
    padding-right: 30px;
    // background-color: brown;
}

.h1ClaimTitle2 {
    padding-left: 30px;
    padding-right: 30px;
    // background-color: brown;
}

.confidenceAndStatsClaim {
    
    background-color: $background-grey;
    min-width: 180px;
    border-radius: 10px;
    box-shadow: $heavy-shadow;
}

.smallConfidenceAndFollow {
    display: none;
}


.confidenceAndStatsClaim2 {
    display: none;
}

.confidenceAndStatsClaim h1 {
    font-size: 120%;
}

.confidenceAndStatsClaim2 h1 {
    font-size: 120%;
}



.followSVGClaim {
    // background-color: blueviolet;
    min-width: 200px;
    height: 100px;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
}

.followSVGClaimSmall {
    display: none;
}

.invisibleClaim {
    
    min-width: 180px;
    height: 100px;
    padding: 60px;
    display: flex;
    align-items: center;
    visibility: hidden;
}

.invisibleClaimSmall {
    
    display: none;
}




.followSVGSVGClaim {
    // background-color: brown;
    border-style: solid;
    border-width: 5px;
    border-color: $dark-green;
    min-width: 50px;
    padding: 5px;
}

.followSVGSVGClaim:hover {
    background-color: $light-green;
    cursor: pointer;
}

.followSVGSVGClaimClicked {
    background-color: $dark-green;
    border-style: solid;
    border-width: 5px;
    border-color: $dark-green;
    min-width: 50px;
    padding: 5px;
}

.followSVGSVGClaimClicked:hover {
    background-color: $light-green;
    cursor: pointer;
}

.ConfidencePercentageDiv {
    display: flex;
    justify-content: space-evenly;
    // background-color: chartreuse;
}

.columnFlex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    // margin-top: 40px;
    // background-color: aqua;
}


.supColumnNotClicked {
    // background-color: green;
    text-align: center;
    justify-content: center;
    width: calc(50% - 10px);
}

.supColumn {
    // background-color: green;
    text-align: center;
    justify-content: center;
    width: calc(50% - 10px);
}



.columnTop {
    display: flex;
    // background-color: blue;
    justify-content: center;
    align-items: center;
}

#input7 {
    background-color: white;
    border-radius: 50%;
    border-color: $light-green;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    padding: 4px;
}

#input7:hover {
    cursor: pointer;
}

#input7Contra {
    background-color: white;
    border-color: $delete-red;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    padding: 4px;
}

#input7Contra:hover {
    cursor: pointer;
}

.contraColumnOriginal {
    background-color: $background-grey;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    width: 49.5%;
    box-shadow: $light-shadow;
}

.contraColumn {
    // background-color: red;
    text-align: center;
    justify-content: center;
    width: calc(50% - 10px);
}

.contraColumnNotClicked {
    // display: none;
    text-align: center;
    justify-content: center;
    width: calc(50% - 10px);
}

.contraColumnH1 {
    background-color: white;
    border-radius: 10px;
    border-color: $delete-red;
    border-style: solid;
    border-width: 2px;
    box-shadow: $light-shadow;
    color: $delete-red;
    // margin-left: auto;
    // margin-right: auto;
    width: 140px;
}

.supportColumnH1 {
    background-color: white;
    border-radius: 10px;
    border-color: $light-green;
    border-style: solid;
    border-width: 2px;
    box-shadow: $light-shadow;
    color: $light-green;
    // margin-left: auto;
    // margin-right: auto;
    width: 110px;
    // align-self: center;
}

.smallFigureContainer {
    background-color: $background-grey;
    color: black;
    border-radius: 20px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    // padding: 2%;
    font-size: 100%;
    margin-bottom: 10px;
    font-family: Helvetica;
    // height: 300px;
    // float: left;
    box-shadow: $light-shadow;
    
    // font-size: small;
}

.divAbove {
    
    // height: 80px;
    // padding-bottom: 10px;
    width: 100%;
    // background-color: red;
    
}

.divAbove2 {
    
    // height: 90px;
    // margin-bottom: 10px;
    // margin-top: 10px;
    width: 100%;
    // background-color: green;
    display: flex;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    // border-bottom: none;
    border-left: none;
    border-right: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    // border-color: $button-grey-hover;
    border-bottom: 1px solid $button-grey;
    border-top: 1px solid $button-grey;
    
}

.divAbove2ClaimTheory {
    // width: 100%;
    // display: flex;
    // align-items: center;
    padding-top: 20px;
    // background-color: yellow;
    // padding-bottom: 20px;
    text-align: left;
}

.paperAnchor {
    text-decoration: none;
    color: blue;
}

.paperAnchor:hover {
    text-decoration: underline;
}

.extractedClaim {
    font-size: 17px;
    display: flex;
    justify-content: baseline;
    padding-top: 5px;
}

.exactTextDivClaim {
    // background-color: red;
    padding-top: 30px;
}

.exactTextQuote {
    font-style: italic;
    padding-left: 3px;
    
}

.anchorNameClaim {
    text-decoration: none;
    color: blue;
    // float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 2px;
    font-size: 14px;
}

.smallFigConImage {
    max-height: 500px;
    overflow: auto;
    // background-color: red;
    // border-bottom: 2px solid $button-grey;
    // border-top: 2px solid $button-grey;
}

.figureShowImage2 {
    cursor: pointer;
    // border-style: solid;
    // border-bottom: black;
    // border-width: 5px;
    // border-color: $button-grey;
    // border-radius: 5px;
    background-color: white;
    width: 100%;
    display: block;
    // max-height: 500px;
}

.claimFigDesc {
    background-color: white;
    border-bottom: 1px solid $button-grey;
    // border-top: 1px solid $button-grey;
    padding: 10px;
    padding-bottom: 0px;
    text-align: justify;
    // text-justify: inter-word;
}
.claimFigDescExplanation {
    background-color: white;
    border-bottom: 1px solid $button-grey;
    border-top: 1px solid $button-grey;
    padding: 10px;
    padding-bottom: 0px;
    text-align: justify;
    // text-justify: inter-word;
}

// .claimFigDesc1 {
//     background-color: white;
//     border-bottom: 1px solid $button-grey;
//     // border-top: 1px solid $button-grey;
//     padding: 10px;
//     text-align: justify;
//     text-justify: inter-word;
// }

.titleDiv {
    // background-color: green;
    width: calc(100% - 50px); 
    float:left;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.titleDiv a {
    text-decoration: none;
    font-weight: bold;
    color: $dark-green;
}

.titleDiv a:hover {
    text-decoration: underline;
}

.floatRightClaim {
    float: right;
    margin-right: 40px;
}

.dropDownClaim {
    position: absolute;
    width: 250px;
    margin-top: 45px;
    margin-left: 50px;
    // padding-top: 10px;
    padding: 5px;
    // float: right;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.dropDownClaim2 {
    position: absolute;
    width: 250px;
    margin-top: 15px;
    margin-left: -150px;
    // padding-top: 10px;
    padding: 5px;
    // float: right;
    border-radius: 5px;
    background-color: white; 
    // margin-right: 100px;
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
    
}

.dropDownClaim3 {
    position: absolute;
    width: 250px;
    margin-top: 50px;
    margin-left: -250px;
    // top: 0px;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    text-align: center;
    justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.dropDownClaimCrit {
    position: absolute;
    width: 230px;
    right: 0px;
    top: 57px;
    z-index: 1000;
    padding: 5px;
    border-radius: 5px;
    background-color: white; 
    // text-align: center;
    // justify-content: center;
    border-color: $light-green;
    border-width: 1px;
    border-style: solid;
    box-shadow: $heavy-shadow;
}

.dropDownClaim a {
    color: $dark-green;
    text-decoration: none;
    font-size: 90%;
}

.dropDownClaim a:hover {
    // color: $light-green;
    // text-decoration: underline;
    
}



.marginSVG {
    margin-right: 15px;
    margin-left: 10px;
}

#areYouSureModalHead {
    font: #002c2c;
    font-size: large;
}

.modalBackgroundClaim {
    cursor: pointer;
    position: fixed;
    z-index: 9001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // margin-right: 50px;
    // position: relative;
    background-color: rgba(0, 0, 0, .8);
    max-height: 100vh;
    overflow-y: auto;
    text-align: center;
    // display: none;
}


.modalBackgroundClaim.active {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    // margin: auto;

}

.modalBackgroundClaim img {
    width: 90%;
    // padding: 4px;
    background-color: black;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5%;
    // margin-left: 5%;
}

#input6Claim {
    background-color: white;
    border-color: $light-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 5px;
    width:7%;
}

#input6ClaimClicked {
    background-color: $light-green;
    border-color: $light-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height: 50px;
    max-width: 50px;
    min-height: 35px;
    min-width: 35px;
    padding: 5px;
    width: 7%;
}

// #input6Claim:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

// #input6Clicked:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

// #input6ClaimClicked:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

#input3Claim {
    background-color: white;
    border-color: $light-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 7px;
    width:7%;
}

// #input3Claim:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

#input3ClaimClicked {
    background-color: $light-green;
    border-color: $light-green;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    width:7%;
    padding: 7px;
}

// #input3ClaimClicked:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }


#openDesc {
    background-color: white;
    background-color: $button-grey;
    border-radius: 27px;
    height: 27px;
    width: 27px;
    padding: 5px;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
}

#openDesc:hover {
    background-color: $button-grey-hover;
    cursor: pointer;
}

#openDescClicked {
    background-color: white;
    background-color: $button-grey;
    border-radius: 27px;
    height: 27px;
    width: 27px;
    padding: 5px;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    transform: rotate(180deg);
    
}

#openDescClicked svg {
    
    transform: rotate(180deg);
}

.theFlexClaim {
    // background-color: green;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px;
    // border-style: solid;
    border-width: 2px;
    border-color: $dark-green;
    // margin-bottom: 5px;
    min-height: 45px;
    min-width: 300px;
}

.theFlexClaimHidden {
    display: hidden;
}

#input6ClaimC {
    background-color: white;
    border-color: $delete-red;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 5px;
    width:7%;
}

#input6ClaimClickedC {
    background-color: $delete-red;
    border-color: $delete-red;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 5px;
    width:7%;
}

// #input6ClaimClickedC:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

// #input6ClaimC:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

// #input6ClickedC:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

#input3ClaimC {
    background-color: white;
    border-color: $delete-red;
    border-radius: 27px;
    border-style: solid;
    border-width: 1px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    padding: 7px;
    width:7%;
}

// #input3ClaimC:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }

#input3ClaimClickedC {
    background-color: $delete-red;
    border-color: $delete-red;
    border-radius: 27px;
    border-style: solid;
    border-width: 3px;
    max-height:50px;
    max-width:50px;
    min-height:35px;
    min-width:35px;
    width:7%;
    padding: 5px;
}

// #input3ClaimClickedC:hover {
//     background-color: $dark-green;
//     cursor: pointer;
// }



.critDivClaim {
    width: 100%;
    // float: left;
    // background-color: aqua;
    display: flex;
    // position: relative;
    
    margin-bottom: 10px;
}
.criticismsDiv {
    max-height: 350px;
    // overflow: auto;
    border-style: solid;
    border-width: 1px;
    border-bottom: blue;
    border-right: white;
    border-left: white;
    border-top: black;
    border-radius: 5px;
}

.theTestClaimCrit {
    position: relative;
}

.relativeClass {
    position: relative;
}

.optionsClaim {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.optionsClaim2 {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.optionsDivClaim {
    display: flex;
    align-items: center;
    // background-color: aqua;
    position: relative;
    // overflow: visible;
}

.h1ClaimTitleSmallDiv {
    display: none;
}

.optionsAnchorDiv {
    // background-color: aquamarine;
    // margin-top: 5px;
    // width: 600px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: left;
    align-items: end;
    font-size: 80%;
  
}

// .dropDownClaimCrit {
//     position: absolute;
//     width: 230px;
//     padding: 5px;
//     // float: right;
//     border-radius: 5px;
//     background-color: white; 
//     // margin-right: 100px;
//     text-align: center;
//     justify-content: center;
//     border-color: $light-green;
//     border-width: 1px;
//     border-style: solid;
//     box-shadow: $heavy-shadow;
    
// }

.optionsAnchorDiv:hover {
    background-color: $lightened-light-green;
    transition: 0.2s;
    cursor: pointer;
}

.critClaim {
    background-color: $background-grey;
    color: black;
    border-radius: 20px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 100%;
    font-family: Helvetica;
    width: 100%;
    // float: left;
    // min-height: 90px;
    display: flex;
    align-items: center;
    box-shadow: $light-shadow;
    font-size: small;
}

.critUnloggedClaim {
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    width: 100%;
    float: left;
    box-shadow: $light-shadow;
}

.claimCritDiv {
    display: flex;
    justify-content: end;
    caret-color: transparent;
    

}

.critDivClaim2 {
    width: calc(100%);
    padding-right: 15px;
    
    // float: left;
    // min-height: 60px;
    
    // margin-bottom: 10px;
}

.anchorNameClaim2 {
    text-decoration: none;
    color: blue;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    // margin-right: 5px;
    // height: 50px;
    padding: 2px;
    text-align: center;
    // justify-content: center;
    align-items: center;
    // display: flex;
    // vertical-align: middle;
    caret-color: transparent;
}

.anchorNameClaim {
    text-decoration: none;
    color: blue;
    // float: left;
    background-color: white;
    border-style: solid;
    border-color: $white-border;
    border-width: 1px;
    border-radius: 5px;
    margin-left: 5px;
    padding: 2px;
    font-size: 14px;
    caret-color: transparent;
}

.anchorNameClaim:hover {
    cursor: pointer;
}

.contraFigButtons {
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 80%;
    // border-style: solid;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    // box-shadow: $grey-border;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    caret-color: transparent;
}

.contraFigButtons:hover {
    background-color: $lightened-delete-red;
    caret-color: transparent;
    cursor: pointer;
}

.supportFigButtons {
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 80%;
    // border-style: solid;
    border-width: 1px;
    box-shadow: $heavy-shadow;
    // box-shadow: $grey-border;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    caret-color: transparent;
}

.supportFigButtons:hover {
    background-color: $lightened-light-green;
    caret-color: transparent;
    cursor: pointer;
}

.critDeleteClaim {
    background-color: rgb(228, 92, 92);
    color: white;
    max-height: 50px; 
    border-radius: 50%;
    float: right; 
    // max-width: 50px; 
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    caret-color: transparent;
    
}

.critDeleteClaim:hover {
    background-color: rgb(233, 152, 152);
}

.critButtonsClaim {
    border-color: $white-border;
    caret-color: transparent;
}

.critClaimButton1 {
    border-color: $white-border;
    padding: 2px;
    padding-right: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: white;
    // margin-right: 5px;
    border-style: solid;
    border-width: 1px;
    caret-color: transparent;
    // width: 70px;
}

.critClaimButton1:hover {
    cursor: pointer;
    background-color: $lightened-delete-red;
}

.critClaimButtonUp {
    border-color: $white-border;
    padding: 2px;
    padding-right: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: white;
    // margin-right: 5px;
    border-style: solid;
    border-width: 1px;
    caret-color: transparent;
    // width: 70px;
}

.critClaimButtonUp:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.critClaimButton2 {
    border-color: $white-border;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    
    border-style: solid;
    border-width: 1px;
    caret-color: transparent;
    // width: 70px;
}

.critClaimButton2:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.critClaimButtonDelete {
    // border-color: $white-border;
    padding: 2px;
    padding-left: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    // background-color: red;
    caret-color: transparent;
    position: relative;
    
    // border-style: solid;
    // border-width: 1px;
    // width: 70px;
}

.critClaimButtonDelete:hover {
    cursor: pointer;
}

.UpClaim {
    
    width: 25px;
    height: 25px;
    margin-right: 5px;
    padding: 3px;
    border-radius: 50%;
    background-color: white;
    border-width: 2px;
    border-color: $light-green;
    border-style: solid;
}

.UpClickedClaim {
    
    width: 25px;
    height: 25px;
    margin-right: 5px;
    padding:3px;
    border-radius: 50%;
    background-color: $light-green;
    border-width: 2px;
    border-color: $light-green;
    border-style: solid;
}

// .UpClaim:hover {
//     background-color: rgb(136, 207, 204);
//     cursor: pointer;
// }

// .UpClickedClaim:hover {
//     background-color: rgb(136, 207, 204);
//     cursor: pointer;
// }

.DownClaim {
   
    width: 25px;
    height: 25px;
    margin-right: 5px;
    padding:3px;
    border-radius: 50%;
    background-color: white;
    border-width: 2px;
    border-color: $delete-red;
    border-style: solid;
    // margin-top: 1px;
}

.DownClickedClaim {
   
    width: 25px;
    height: 25px;
    margin-right: 5px;
    padding:3px;
    border-radius: 50%;
    background-color: $delete-red;
    border-width: 2px;
    border-color: $delete-red;
    border-style: solid;
    // margin-top: 1px;
}

// .DownClaim:hover {
//     background-color: rgb(136, 207, 204);
//     cursor: pointer;
// }

// .DownClickedClaim:hover {
//     background-color: rgb(136, 207, 204);
//     cursor: pointer;
// }

.deleteCritClaimSVG {
    width: 25px;
    height: 25px;
    // margin-right: 5px;
    padding:5px;
    border-radius: 50%;
    background-color: $button-grey;
    // border-width: 1px;
    // border-color: $dark-green;
    // border-style: solid;
}

.deleteCritClaimSVG:hover {
    background-color: $button-grey-hover;
}

.claimCritDiv5 {
    // background-color: aqua;
    // height: 100px;
}

.formDivClaim {
    margin-top: 10px;
}

.postAsClaimCrit {
    // background-color: green;
    // width: calc(100% - 39px); 
    // float: left;
    display: flex;
    padding-bottom: 15px;
    
    // margin-bottom: 100px;
}

.postAsSpanClaim {
    // background-color: yellow;
    // padding-top: 2px;
    font-size: 90%;
    
}

.tagsClaim {
    // border-style: solid;
    border-color: $grey-border;
    border-width: 2px;
    // border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    // background-color: rgb(221, 26, 146);
}

.newCritClaim {
    width: calc(100% - 39px); 
    // float: left;
    font-family: Arial, Helvetica, sans-serif;   
    background-color: $background-grey;
    color: black;
    border-radius: 4px;
    border-color: $grey-border;
    border-style: solid;
    border-width: 1px;
    padding: 2%;
    font-size: 100%;
    font-family: Helvetica;
    box-shadow: $light-shadow;
    resize: vertical;
    margin-bottom: 10px;
}

.addDivClaim {
    background-color: rgb(98, 189, 184);
    color: white;
    max-height: 50px; 
    border-radius: 50%;
    float: right; 
    // max-width: 50px; 
    width: 34px;
    height: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 6px;
}

.deleteButtonClaim {
    // padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $dark-green;
    height: 40px;
    width: 80px;
}

.deleteButtonClaimClicked {
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $lightened-light-green;
    height: 40px;
    width: 80px;
}
.deleteButtonClaim:hover {
    cursor: pointer;
    background-color: $lightened-light-green;
}

.deleteButtonClaimWrapper {
    height: 70px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.addDivClaim:hover {
    background-color: $lightened-light-green;
}

.redFontClaim {
    color: $delete-red;
    margin-left: 10px;
    text-decoration: underline;
    font-size: 90%;
}

.checkBox1Claim {
    padding-top: 2px;
    height: 18px;
    width: 18px;
    margin-right: 4px;
    vertical-align: bottom;
    // margin-bottom: 5px;
}

.firstCheckClaim {
    // background-color: yellow;
    // width: 200px;
    font-size: 90%;
}

.checkBoxClaim {
    padding-top: 2px;
    height: 18px;
    width: 18px;
    vertical-align: bottom;
}

.labelClaim {
    margin-left: 4px;
    margin-bottom: 20px;
}

.noCriticismsClaim {
    font-size: 90%;
    text-align: center;
}

.addedBy {
    font-size: 14px;
}

.deleteModalClaimCriticism {
    text-align: center;
    color: $dark-green;
    // background-color: #002c2c;
}

.directionToggle {
    display: none;
}

.contraFigButtonsToggle {
    background-color: white;
    border-radius: 10px;
    border-color: $delete-red;
    border-style: solid;
    border-width: 2px;
    box-shadow: $light-shadow;
    color: $delete-red;
    // margin-left: auto;
    // margin-right: auto;
    width: 100px;
    // background-color: white;
    // padding: 10px;
    // font-size: 100%;
    // // margin-right: 10px;
    // // border-style: solid;
    // border-width: 1px;
    // box-shadow: $heavy-shadow;
    // // box-shadow: $grey-border;
    // width: 100px;
    // border-radius: 10px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
    caret-color: transparent;
}

.contraFigButtonsToggleClicked {
    background-color: $delete-red;
    border-radius: 10px;
    border-color: $delete-red;
    border-style: solid;
    border-width: 2px;
    box-shadow: $light-shadow;
    color: white;
    // margin-left: auto;
    // margin-right: auto;
    width: 100px;
    // background-color: white;
    // padding: 10px;
    // font-size: 100%;
    // // margin-right: 10px;
    // // border-style: solid;
    // border-width: 1px;
    // box-shadow: $heavy-shadow;
    // // box-shadow: $grey-border;
    // width: 100px;
    // border-radius: 10px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
    caret-color: transparent;
}



.contraFigButtonsToggle:hover {
    background-color: $lightened-delete-red;
    caret-color: transparent;
    cursor: pointer;
    transition: 0.2s;
}

.supportFigButtonsToggle {
    background-color: white;
    border-radius: 10px;
    border-color: $light-green;
    border-style: solid;
    border-width: 2px;
    box-shadow: $light-shadow;
    color: $light-green;
    // margin-left: auto;
    // margin-right: auto;
    width: 100px;
    // background-color: white;
    // padding: 10px;
    // font-size: 100%;
    margin-right: 10px;
    // // border-style: solid;
    // border-width: 1px;
    // box-shadow: $heavy-shadow;
    // // box-shadow: $grey-border;
    // width: 100px;
    // border-radius: 10px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
    caret-color: transparent;
}

.supportFigButtonsToggleClicked {
    background-color: $light-green;
    border-radius: 10px;
    border-color: $light-green;
    border-style: solid;
    border-width: 2px;
    box-shadow: $light-shadow;
    color: white;
    // margin-left: auto;
    // margin-right: auto;
    width: 100px;
    // background-color: white;
    // padding: 10px;
    // font-size: 100%;
    margin-right: 10px;
    // // border-style: solid;
    // border-width: 1px;
    // box-shadow: $heavy-shadow;
    // // box-shadow: $grey-border;
    // width: 100px;
    // border-radius: 10px;
    display: none;
    justify-content: space-evenly;
    align-items: center;
    caret-color: transparent;
}

.supportFigButtonsToggle:hover {
    background-color: $lightened-light-green;
    caret-color: transparent;
    cursor: pointer;
    transition: 0.2s;
}

.toggleButtonsDiv {
    display: none;
    height: 40px;
    // padding-top: 20px;
    // background-color: aqua;
}

.noFiguresClaim {
    height: 300px;
    // background-color: yellow;
    display: flex;
    // vertical-align: middle;
    justify-content: center;
    align-items: center;
    // align-content: center;
    // flex-direction: column;
}


.divAbove2Theory {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 40px;
    // padding-bottom: 20px;
}

.divAbove3Theory {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 20px;
    font-style: italic;
}

.divAbove3TheoryAssociated {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 20px;
    font-style: italic;
    color:blue;

}

.linkVisited:link, .linkVisited:visited {
    color: blue;
    text-decoration: none;
}

.linkVisited2:link, .linkVisited2:visited {
    color: blue;
    text-decoration: none;
    margin-left: 3px;
    font-weight: bold;
}

.blackFont {
    color:black;
}

.dropDownClaimWrapper {
    position: absolute;
}

.blueFont {
    color: blue;
}

.disconnectFigureButton {
    margin-top: 20px;
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $dark-green;
    min-width: 110px;
}

.disconnectFigureButtonClicked {
    margin-top: 20px;
    padding: 8px;
    color: white;
    border-radius: 5px;
    border-color: $dark-green;
    background-color: $light-green;
    min-width: 110px;

}

.disconnectFigureButton:hover {
    cursor: pointer;
    background-color: $light-green;
}

.ClaimButtonFlexEnd {
    display: flex;
    justify-content: right;
    // padding-top: 20px;
}

.ClaimRedFont {
    color: $delete-red;
    // margin-left: 10px;
    text-decoration: underline;
    width: 100%;
    margin-bottom: 10px;
}


@media (max-width: 1000px) {

    .h1ClaimTitle {
        display: none;
    }

    .h1ClaimTitleSmallDiv {
        display: flex;
        justify-content: center;
    }

    .toggleButtonsDiv {
        display: flex;
        // justify-content: center;
        // padding-top: 50px;

    }

    .contraFigButtonsToggle {
        display: flex;
        
    }

    .contraFigButtonsToggleClicked {
        display: flex;
        
    }

    .supportFigButtonsToggle {
        display: flex;
    }

    .supportFigButtonsToggleClicked {
        display: flex;
    }

    .columnFlex {
        display: block;
        // flex-wrap: nowrap;
        // justify-content: space-between;
        // margin-top: 10px;
    }

    .supColumn {
        // background-color: green;
        // text-align: center;
        // justify-content: center;
        width: 100%;
    }

    .contraColumn {
        // background-color: red;
        // text-align: center;
        // justify-content: center;
        width: 100%;
    }

    .claimTitleandStats {
        display: block;
        // background-color: red;
    }

    .followSVGSVGClaim {
        border-style: solid;
        border-width: 5px;
        border-color: $dark-green;
        min-width: 50px;
        padding: 5px;
    }

    .followSVGSVGClaimClicked {
        background-color: $dark-green;
        border-style: solid;
        border-width: 5px;
        border-color: $dark-green;
        min-width: 50px;
        padding: 5px;
    }

    

    .confidenceAndStatsClaim {
        display: none;
    }

    .followSVGClaim {
        display: none;
        
    }

    .invisibleClaim {
        display: none;
    }

    .smallConfidenceAndFollow {
        padding-top: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .confidenceAndStatsClaim2 {
        display: block;
        background-color: $background-grey;
        min-width: 180px;
        border-radius: 10px;
        box-shadow: $heavy-shadow;
    }
    .followSVGClaimSmall {
        // background-color: blueviolet;
        // min-width: 180px;
        // height: 100px;
        // padding: 60px;
        display: flex;
        align-items: center;
    }

    .invisibleClaimSmall {
        display: flex;
        align-items: center;
        visibility: hidden;
    }


    .h1ClaimTitle {
        padding-left: 0px;
        padding-right: 0px;
        
        // background-color: brown;
    }

    


    .directionToggle {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .supportToggle {
        font-size: 120%;
        width: 120px;
        background-color: white;
        border-color: $light-green;
        border-style: solid;
        border-radius: 5px;
        color: $light-green;
    }

    .supportToggleClicked {
        font-size: 120%;
        width: 120px;
        background-color: $light-green;
        border-color: $light-green;
        border-style: solid;
        border-radius: 5px;
        color: white;
    }

    .supportToggleClicked:hover {
        cursor: pointer;
    }

    .supportToggle:hover {
        cursor: pointer;
    }

    .contradictToggle {
        margin-left: 10px;
        font-size: 120%;
        width: 120px;
        background-color: white;
        border-color: $delete-red;
        border-style: solid;
        border-radius: 5px;
        color: $delete-red;
        
    }

    .contradictToggle {
        cursor: pointer;
    }

    .contradictToggleClicked {
        margin-left: 10px;
        font-size: 120%;
        width: 120px;
        background-color: $delete-red;
        border-color: $delete-red;
        border-style: solid;
        border-radius: 5px;
        color: white;
    }

    .contradictToggleClicked:hover {
        cursor: pointer;
    }
    
    .togglesDiv {
        background-color: $background-grey;
        border-style: solid;
        border-color: $grey-border;
        // box-shadow: $light-shadow;
        border-width: 1px;
        display: flex;
        height: 50px;
        padding: 10px;
        border-radius: 10px;
    }

    .supColumnNotClicked {
        display: none;
    }

    .contraColumnNotClicked {
        display: none;
    }
}